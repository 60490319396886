export const RESET_LOADING = 'RESET_LOADING';

export const CHANGE_LOCALE = 'CHANGE_LOCALE';

export const RESET_HELP = 'RESET_HELP';

export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

export const FETCH_HELP = 'FETCH_HELP';
export const FETCH_HELP_SUCCESS = 'FETCH_HELP_SUCCESS';
export const FETCH_HELP_FAILURE = 'FETCH_HELP_FAILURE';

export const RESET_LOGIN_ERROR_MESSAGE = 'RESET_LOGIN_ERROR_MESSAGE';
export const RESET_PROFILE_ERROR_MESSAGE = 'RESET_PROFILE_ERROR_MESSAGE';

export const DO_LOGOUT = 'DO_LOGOUT';

export const DO_LOGIN = 'DO_LOGIN';
export const DO_LOGIN_SUCCESS = 'DO_LOGIN_SUCCESS';
export const DO_LOGIN_FAILURE = 'DO_LOGIN_FAILURE';

export const DO_LOGIN_PLAY_JOIN = 'DO_LOGIN_PLAY_JOIN';
export const DO_LOGIN_PLAY_JOIN_SUCCESS = 'DO_LOGIN_PLAY_JOIN_SUCCESS';
export const DO_LOGIN_PLAY_JOIN_SUCCESS2 = 'DO_LOGIN_PLAY_JOIN_SUCCESS2';
export const DO_LOGIN_PLAY_JOIN_FAILURE = 'DO_LOGIN_PLAY_JOIN_FAILURE';

export const DO_LOGIN_SCREEN = 'DO_LOGIN_SCREEN';
export const DO_LOGIN_SCREEN_SUCCESS = 'DO_LOGIN_SCREEN_SUCCESS';
export const DO_LOGIN_SCREEN_FAILURE = 'DO_LOGIN_SCREEN_FAILURE';

export const DO_LOGIN_MFA = 'DO_LOGIN_MFA';
export const DO_LOGIN_MFA_SUCCESS = 'DO_LOGIN_MFA_SUCCESS';
export const DO_LOGIN_MFA_FAILURE = 'DO_LOGIN_MFA_FAILURE';

export const DO_REGISTER = 'DO_REGISTER';
export const DO_REGISTER_SUCCESS = 'DO_REGISTER_SUCCESS';
export const DO_REGISTER_FAILURE = 'DO_REGISTER_FAILURE';

export const DO_REGISTER_GENERATE_OTP = 'DO_REGISTER_GENERATE_OTP';
export const DO_REGISTER_GENERATE_OTP_SUCCESS = 'DO_REGISTER_GENERATE_OTP_SUCCESS';
export const DO_REGISTER_GENERATE_OTP_FAILURE = 'DO_REGISTER_GENERATE_OTP_FAILURE';

export const DO_SEND_OTP = 'DO_SEND_OTP';
export const DO_SEND_OTP_SUCCESS = 'DO_SEND_OTP_SUCCESS';
export const DO_SEND_OTP_FAILURE = 'DO_SEND_OTP_FAILURE';

export const DO_RESET_PASSWORD = 'DO_RESET_PASSWORD';
export const DO_RESET_PASSWORD_SUCCESS = 'DO_RESET_PASSWORD_SUCCESS';
export const DO_RESET_PASSWORD_FAILURE = 'DO_RESET_PASSWORD_FAILURE';

export const DO_REDEEM_CODE = 'DO_REDEEM_CODE';
export const DO_REDEEM_CODE_SUCCESS = 'DO_REDEEM_CODE_SUCCESS';
export const DO_REDEEM_CODE_FAILURE = 'DO_REDEEM_CODE_FAILURE';
export const RESET_REDEEM_CODE_ACTIVITY = 'RESET_REDEEM_CODE_ACTIVITY';
export const RESET_REDEEM_CODE_ERROR_MESSAGE = 'RESET_REDEEM_CODE_ERROR_MESSAGE';

export const FETCH_CATEGORIES = 'FETCH_CATEGORIES';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_BANKS = 'FETCH_BANKS';
export const FETCH_BANKS_SUCCESS = 'FETCH_BANKS_SUCCESS';
export const FETCH_BANKS_FAILURE = 'FETCH_BANKS_FAILURE';

export const DO_BANK_DETAILS = 'DO_BANK_DETAILS';
export const DO_BANK_DETAILS_SUCCESS = 'DO_BANK_DETAILS_SUCCESS';
export const DO_BANK_DETAILS_FAILURE = 'DO_BANK_DETAILS_FAILURE';

export const RESET_REGIONS_DATA = 'RESET_REGIONS_DATA';
export const RESET_ACTIVITY_REGIONS_DATA = 'RESET_ACTIVITY_REGIONS_DATA';
export const RESET_S_REGIONS_DATA = 'RESET_ACTIVITY_REGIONS_DATA';
export const RESET_S_ACTIVITY_REGIONS_DATA = 'RESET_S_ACTIVITY_REGIONS_DATA';
export const RESET_LOCALITIES_DATA = 'RESET_LOCALITIES_DATA';
export const RESET_ACTIVITY_LOCALITIES_DATA = 'RESET_ACTIVITY_LOCALITIES_DATA';
export const RESET_S_LOCALITIES_DATA = 'RESET_S_LOCALITIES_DATA';
export const RESET_S_ACTIVITY_LOCALITIES_DATA = 'RESET_S_ACTIVITY_LOCALITIES_DATA';
export const RESET_SUB_LOCALITIES_DATA = 'RESET_SUB_LOCALITIES_DATA';
export const RESET_ACTIVITY_SUB_LOCALITIES_DATA = 'RESET_ACTIVITY_SUB_LOCALITIES_DATA';
export const RESET_S_SUB_LOCALITIES_DATA = 'RESET_S_SUB_LOCALITIES_DATA';
export const RESET_S_ACTIVITY_SUB_LOCALITIES_DATA = 'RESET_S_ACTIVITY_SUB_LOCALITIES_DATA';
export const RESET_EMPLOYEE_LOCALITIES_DATA = 'RESET_EMPLOYEE_LOCALITIES_DATA';
export const RESET_S_EMPLOYEE_LOCALITIES_DATA = 'RESET_S_EMPLOYEE_LOCALITIES_DATA';

export const FETCH_REGIONS = 'FETCH_REGIONS';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';

export const FETCH_ACTIVITY_REGIONS = 'FETCH_ACTIVITY_REGIONS';
export const FETCH_ACTIVITY_REGIONS_SUCCESS = 'FETCH_ACTIVITY_REGIONS_SUCCESS';
export const FETCH_ACTIVITY_REGIONS_FAILURE = 'FETCH_ACTIVITY_REGIONS_FAILURE';

export const FETCH_S_ACTIVITY_REGIONS = 'FETCH_S_ACTIVITY_REGIONS';
export const FETCH_S_ACTIVITY_REGIONS_SUCCESS = 'FETCH_S_ACTIVITY_REGIONS_SUCCESS';
export const FETCH_S_ACTIVITY_REGIONS_FAILURE = 'FETCH_S_ACTIVITY_REGIONS_FAILURE';

export const FETCH_S_REGIONS = 'FETCH_S_REGIONS';
export const FETCH_S_REGIONS_SUCCESS = 'FETCH_S_REGIONS_SUCCESS';
export const FETCH_S_REGIONS_FAILURE = 'FETCH_S_REGIONS_FAILURE';

export const FETCH_LOCALITIES = 'FETCH_LOCALITIES';
export const FETCH_LOCALITIES_SUCCESS = 'FETCH_LOCALITIES_SUCCESS';
export const FETCH_LOCALITIES_FAILURE = 'FETCH_LOCALITIES_FAILURE';

export const FETCH_EMPLOYEE_LOCALITIES = 'FETCH_EMPLOYEE_LOCALITIES';
export const FETCH_EMPLOYEE_LOCALITIES_SUCCESS = 'FETCH_EMPLOYEE_LOCALITIES_SUCCESS';
export const FETCH_EMPLOYEE_LOCALITIES_FAILURE = 'FETCH_EMPLOYEE_LOCALITIES_FAILURE';

export const FETCH_ACTIVITY_LOCALITIES = 'FETCH_ACTIVITY_LOCALITIES';
export const FETCH_ACTIVITY_LOCALITIES_SUCCESS = 'FETCH_ACTIVITY_LOCALITIES_SUCCESS';
export const FETCH_ACTIVITY_LOCALITIES_FAILURE = 'FETCH_ACTIVITY_LOCALITIES_FAILURE';

export const FETCH_S_LOCALITIES = 'FETCH_S_LOCALITIES';
export const FETCH_S_LOCALITIES_SUCCESS = 'FETCH_S_LOCALITIES_SUCCESS';
export const FETCH_S_LOCALITIES_FAILURE = 'FETCH_S_LOCALITIES_FAILURE';

export const FETCH_S_ACTIVITY_LOCALITIES = 'FETCH_S_ACTIVITY_LOCALITIES';
export const FETCH_S_ACTIVITY_LOCALITIES_SUCCESS = 'FETCH_S_ACTIVITY_LOCALITIES_SUCCESS';
export const FETCH_S_ACTIVITY_LOCALITIES_FAILURE = 'FETCH_S_ACTIVITY_LOCALITIES_FAILURE';

export const FETCH_SUB_LOCALITIES = 'FETCH_SUB_LOCALITIES';
export const FETCH_SUB_LOCALITIES_SUCCESS = 'FETCH_SUB_LOCALITIES_SUCCESS';
export const FETCH_SUB_LOCALITIES_FAILURE = 'FETCH_SUB_LOCALITIES_FAILURE';

export const FETCH_ACTIVITY_SUB_LOCALITIES = 'FETCH_ACTIVITY_SUB_LOCALITIES';
export const FETCH_ACTIVITY_SUB_LOCALITIES_SUCCESS = 'FETCH_ACTIVITY_SUB_LOCALITIES_SUCCESS';
export const FETCH_ACTIVITY_SUB_LOCALITIES_FAILURE = 'FETCH_ACTIVITY_SUB_LOCALITIES_FAILURE';

export const FETCH_S_SUB_LOCALITIES = 'FETCH_S_SUB_LOCALITIES';
export const FETCH_S_SUB_LOCALITIES_SUCCESS = 'FETCH_S_SUB_LOCALITIES_SUCCESS';
export const FETCH_S_SUB_LOCALITIES_FAILURE = 'FETCH_S_SUB_LOCALITIES_FAILURE';

export const FETCH_S_ACTIVITY_SUB_LOCALITIES = 'FETCH_S_ACTIVITY_SUB_LOCALITIES';
export const FETCH_S_ACTIVITY_SUB_LOCALITIES_SUCCESS = 'FETCH_S_ACTIVITY_SUB_LOCALITIES_SUCCESS';
export const FETCH_S_ACTIVITY_SUB_LOCALITIES_FAILURE = 'FETCH_S_ACTIVITY_SUB_LOCALITIES_FAILURE';

export const FETCH_S_EMPLOYEE_LOCALITIES = 'FETCH_S_EMPLOYEE_LOCALITIES';
export const FETCH_S_EMPLOYEE_LOCALITIES_SUCCESS = 'FETCH_S_EMPLOYEE_LOCALITIES_SUCCESS';
export const FETCH_S_EMPLOYEE_LOCALITIES_FAILURE = 'FETCH_S_EMPLOYEE_LOCALITIES_FAILURE';

export const FETCH_AGE_GROUP = 'FETCH_AGE_GROUP';
export const FETCH_AGE_GROUP_SUCCESS = 'FETCH_AGE_GROUP_SUCCESS';
export const FETCH_AGE_GROUP_FAILURE = 'FETCH_AGE_GROUP_FAILURE';

export const FETCH_ACTIVITY_NATIONALITIES = 'FETCH_ACTIVITY_NATIONALITIES';
export const FETCH_ACTIVITY_NATIONALITIES_SUCCESS = 'FETCH_ACTIVITY_NATIONALITIES_SUCCESS';
export const FETCH_ACTIVITY_NATIONALITIES_FAILURE = 'FETCH_ACTIVITY_NATIONALITIES_FAILURE';

export const RESET_COMPANIES_DATA = 'RESET_COMPANIES_DATA';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const FETCH_COUNTRIES = 'FETCH_COUNTRIES';
export const FETCH_COUNTRIES_SUCCESS = 'FETCH_COUNTRIES_SUCCESS';
export const FETCH_COUNTRIES_FAILURE = 'FETCH_COUNTRIES_FAILURE';

export const RESET_ACTIVTIY_COMPANIES_DATA = 'RESET_ACTIVTIY_COMPANIES_DATA';

export const FETCH_ACTIVITY_COMPANIES = 'FETCH_ACTIVITY_COMPANIES';
export const FETCH_ACTIVITY_COMPANIES_SUCCESS = 'FETCH_ACTIVITY_COMPANIES_SUCCESS';
export const FETCH_ACTIVITY_COMPANIES_FAILURE = 'FETCH_ACTIVITY_COMPANIES_FAILURE';

export const RESET_S_COMPANIES_DATA = 'RESET_S_COMPANIES_DATA';

export const FETCH_S_COMPANIES = 'FETCH_S_COMPANIES';
export const FETCH_S_COMPANIES_SUCCESS = 'FETCH_S_COMPANIES_SUCCESS';
export const FETCH_S_COMPANIES_FAILURE = 'FETCH_S_COMPANIES_FAILURE';

export const RESET_S_ACTIVTIY_COMPANIES_DATA = 'RESET_S_ACTIVTIY_COMPANIES_DATA';

export const FETCH_S_ACTIVITY_COMPANIES = 'FETCH_S_ACTIVITY_COMPANIES';
export const FETCH_S_ACTIVITY_COMPANIES_SUCCESS = 'FETCH_S_ACTIVITY_COMPANIES_SUCCESS';
export const FETCH_S_ACTIVITY_COMPANIES_FAILURE = 'FETCH_S_ACTIVITY_COMPANIES_FAILURE';

export const FETCH_COMPANY_LEVELS = 'FETCH_COMPANY_LEVELS';
export const FETCH_COMPANY_LEVELS_SUCCESS = 'FETCH_COMPANY_LEVELS_SUCCESS';
export const FETCH_COMPANY_LEVELS_FAILURE = 'FETCH_COMPANY_LEVELS_FAILURE';

/**
 * Registration
 */
export const UPDATE_REGISTRATION_NEXT_STEP = 'UPDATE_REGISTRATION_NEXT_STEP';
export const UPDATE_REGISTRATION_DATA = 'UPDATE_REGISTRATION_DATA';
export const VALIDATE_REGISTRATION_DATA = 'VALIDATE_REGISTRATION_DATA';
export const RESET_REGISTRATION_DATA = 'RESET_REGISTRATION_DATA';
export const SHOW_VALIDATE_MOBILE_MODAL = 'SHOW_VALIDATE_MOBILE_MODAL';
export const HIDE_VALIDATE_MOBILE_MODAL = 'HIDE_VALIDATE_MOBILE_MODAL';

export const DO_REGISTRATION_VERIFICATION = 'DO_REGISTRATION_VERIFICATION';
export const DO_REGISTRATION_VERIFICATION_SUCCESS = 'DO_REGISTRATION_VERIFICATION_SUCCESS';
export const DO_REGISTRATION_VERIFICATION_FAILURE = 'DO_REGISTRATION_VERIFICATION_FAILURE';

export const DO_REGISTRATION_VERIFICATION_CODE = 'DO_REGISTRATION_VERIFICATION_CODE';
export const DO_REGISTRATION_VERIFICATION_CODE_SUCCESS = 'DO_REGISTRATION_VERIFICATION_CODE_SUCCESS';
export const DO_REGISTRATION_VERIFICATION_CODE_FAILURE = 'DO_REGISTRATION_VERIFICATION_CODE_FAILURE';

export const RESET_REGISTRATION_ERROR_MESSAGE = 'RESET_REGISTRATION_ERROR_MESSAGE';
export const DO_REGISTRATION = 'DO_REGISTRATION';
export const DO_REGISTRATION_SUCCESS = 'DO_REGISTRATION_SUCCESS';
export const DO_REGISTRATION_FAILURE = 'DO_REGISTRATION_FAILURE';

/**
 * Notification Actions
 */
export const SHOW_NOTIFICATIONS_MENU = 'SHOW_NOTIFICATIONS_MENU';
export const HIDE_NOTIFICATIONS_MENU = 'HIDE_NOTIFICATIONS_MENU';

export const DO_UPDATE_NOTIFICATION_STATE = 'DO_UPDATE_NOTIFICATION_STATE';
export const DO_UPDATE_NOTIFICATION_STATE_SUCCESS = 'DO_UPDATE_NOTIFICATION_STATE_SUCCESS';
export const DO_UPDATE_NOTIFICATION_STATE_FAILURE = 'DO_UPDATE_NOTIFICATION_STATE_FAILURE';

export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const DELETE_NOTIFICATION_SUCCESS = 'DELETE_NOTIFICATION_SUCCESS';
export const DELETE_NOTIFICATION_FAILURE = 'DELETE_NOTIFICATION_FAILURE';

export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS_SUCCESS = 'DELETE_NOTIFICATIONS_SUCCESS';
export const DELETE_NOTIFICATIONS_FAILURE = 'DELETE_NOTIFICATIONS_FAILURE';

export const SHOW_SYSTEM_NOTIFICATION_MODAL = 'SHOW_SYSTEM_NOTIFICATION_MODAL';
export const HIDE_SYSTEM_NOTIFICATION_MODAL = 'HIDE_SYSTEM_NOTIFICATION_MODAL';

/**
 * Profile Action
 */
export const SHOW_CHANGE_PASSWORD_MODAL = 'SHOW_CHANGE_PASSWORD_MODAL';
export const HIDE_CHANGE_PASSWORD_MODAL = 'HIDE_CHANGE_PASSWORD_MODAL';

export const DO_CHANGE_PASSWORD = 'DO_CHANGE_PASSWORD';
export const DO_CHANGE_PASSWORD_SUCCESS = 'DO_CHANGE_PASSWORD_SUCCESS';
export const DO_CHANGE_PASSWORD_FAILURE = 'DO_CHANGE_PASSWORD_FAILURE';
export const RESET_CHANGE_PASSWORD_ERROR_MESSAGE = 'RESET_CHANGE_PASSWORD_ERROR_MESSAGE';

export const SHOW_CHANGE_SETTINGS_MODAL = 'SHOW_CHANGE_SETTINGS_MODAL';
export const HIDE_CHANGE_SETTINGS_MODAL = 'HIDE_CHANGE_SETTINGS_MODAL';

export const DO_CHANGE_SETTINGS = 'DO_CHANGE_SETTINGS';
export const DO_CHANGE_SETTINGS_SUCCESS = 'DO_CHANGE_SETTINGS_SUCCESS';
export const DO_CHANGE_SETTINGS_FAILURE = 'DO_CHANGE_SETTINGS_FAILURE';

export const DO_REGISTER_DEVICE = 'DO_REGISTER_DEVICE';
export const DO_REGISTER_DEVICE_SUCCESS = 'DO_REGISTER_DEVICE_SUCCESS';
export const DO_REGISTER_DEVICE_FAILURE = 'DO_REGISTER_DEVICE_FAILURE';

export const UPDATE_PROFILE_MODE = 'UPDATE_PROFILE_MODE';
export const UPDATE_PROFILE_DATA = 'UPDATE_PROFILE_DATA';
export const VALIDATE_PROFILE_DATA = 'VALIDATE_PROFILE_DATA';
export const RESET_PROFILE_DATA = 'RESET_PROFILE_DATA';
export const CANCEL_PROFILE_UPDATE = 'CANCEL_PROFILE_UPDATE';

export const FETCH_PROFILE = 'FETCH_PROFILE';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const FETCH_HOME = 'FETCH_HOME';
export const FETCH_HOME_SUCCESS = 'FETCH_HOME_SUCCESS';
export const FETCH_HOME_FAILURE = 'FETCH_HOME_FAILURE';

export const DO_ACCOUNT_UPDATE = 'DO_ACCOUNT_UPDATE';
export const DO_ACCOUNT_UPDATE_SUCCESS = 'DO_ACCOUNT_UPDATE_SUCCESS';
export const DO_ACCOUNT_UPDATE_FAILURE = 'DO_ACCOUNT_UPDATE_FAILURE';

export const REFRESH_USER_FROM_STORE = 'REFRESH_USER_FROM_STORE';
export const RESET_INITIATE_VERIFY_ACCOUNT_ERROR_MESSAGE = 'RESET_INITIATE_VERIFY_ACCOUNT_ERROR_MESSAGE';
export const DO_INITIATE_VERIFY_ACCOUNT = 'DO_INITIATE_VERIFY_ACCOUNT';
export const DO_INITIATE_VERIFY_ACCOUNT_SUCCESS = 'DO_INITIATE_VERIFY_ACCOUNT_SUCCESS';
export const DO_INITIATE_VERIFY_ACCOUNT_FAILURE = 'DO_INITIATE_VERIFY_ACCOUNT_FAILURE';

export const DO_VERIFY_ACCOUNT = 'DO_VERIFY_ACCOUNT';
export const DO_VERIFY_ACCOUNT_SUCCESS = 'DO_VERIFY_ACCOUNT_SUCCESS';
export const DO_VERIFY_ACCOUNT_FAILURE = 'DO_VERIFY_ACCOUNT_FAILURE';

export const DO_PROFILE_UPDATE = 'DO_PROFILE_UPDATE';
export const DO_PROFILE_UPDATE_SUCCESS = 'DO_PROFILE_UPDATE_SUCCESS';
export const DO_PROFILE_UPDATE_FAILURE = 'DO_PROFILE_UPDATE_FAILURE';

export const DO_SETTING_NOTIFICATION_UPDATE = 'DO_SETTING_NOTIFICATION_UPDATE';
export const DO_SETTING_NOTIFICATION_UPDATE_SUCCESS = 'DO_SETTING_NOTIFICATION_UPDATE_SUCCESS';
export const DO_SETTING_NOTIFICATION_UPDATE_FAILURE = 'DO_SETTING_NOTIFICATION_UPDATE_FAILURE';

export const SHOW_CHANGE_MOBILE_MODAL = 'SHOW_CHANGE_MOBILE_MODAL';
export const HIDE_CHANGE_MOBILE_MODAL = 'HIDE_CHANGE_MOBILE_MODAL';

export const DO_FETCH_MOBILE_VERIFICATION = 'DO_FETCH_MOBILE_VERIFICATION';
export const DO_FETCH_MOBILE_VERIFICATION_SUCCESS = 'DO_FETCH_MOBILE_VERIFICATION_SUCCESS';
export const DO_FETCH_MOBILE_VERIFICATION_FAILURE = 'DO_FETCH_MOBILE_VERIFICATION_FAILURE';

export const DO_CHANGE_MOBILE_NUMBER = 'DO_CHANGE_MOBILE_NUMBER';
export const DO_CHANGE_MOBILE_NUMBER_SUCCESS = 'DO_CHANGE_MOBILE_NUMBER_SUCCESS';
export const DO_CHANGE_MOBILE_NUMBER_FAILURE = 'DO_CHANGE_MOBILE_NUMBER_FAILURE';

export const FETCH_PAYMENT_CARDS = 'FETCH_PAYMENT_CARDS';
export const DELETE_PAYMENT_CARD = 'DELETE_PAYMENT_CARD';
export const DELETE_PAYMENT_CARD_SUCCESS = 'DELETE_PAYMENT_CARD_SUCCESS';
export const DELETE_PAYMENT_CARD_FAILURE = 'DELETE_PAYMENT_CARD_FAILURE';

/**
 * Games
 */
export const FETCH_CACHE_GAMES = 'FETCH_CACHE_GAMES';
export const FETCH_GAMES = 'FETCH_GAMES';
export const FETCH_GAMES_SUCCESS = 'FETCH_GAMES_SUCCESS';
export const FETCH_GAMES_FAILURE = 'FETCH_GAMES_FAILURE';
export const RESET_GAMES_ERROR = 'RESET_GAMES_ERROR';

export const FETCH_GAME = 'FETCH_GAME';
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS';
export const FETCH_GAME_FAILURE = 'FETCH_GAME_FAILURE';
export const FETCH_GAME_NO_LOADING = 'FETCH_GAME_NO_LOADING';
export const FETCH_GAME_NO_LOADING_SUCCESS = 'FETCH_GAME_NO_LOADING_SUCCESS';
export const FETCH_GAME_NO_LOADING_FAILURE = 'FETCH_GAME_NO_LOADING_FAILURE';
export const VALIDATE_UPLOAD_GAME_MEDIA = 'VALIDATE_UPLOAD_GAME_MEDIA';

export const TOGGLE_GAME_STATE = 'TOGGLE_GAME_STATE';
export const TOGGLE_GAME_STATE_SUCCESS = 'TOGGLE_GAME_STATE_SUCCESS';
export const TOGGLE_GAME_STATE_FAILURE = 'TOGGLE_GAME_STATE_FAILURE';

export const DELETE_GAME = 'DELETE_GAME';
export const DELETE_GAME_SUCCESS = 'DELETE_GAME_SUCCESS';
export const DELETE_GAME_FAILURE = 'DELETE_GAME_FAILURE';

export const MANAGE_GAME = 'MANAGE_GAME';
export const MANAGE_GAME_SUCCESS = 'MANAGE_GAME_SUCCESS';
export const MANAGE_GAME_FAILURE = 'MANAGE_GAME_FAILURE';
export const RESET_MANAGE_GAME_ERROR_MESSAGE = 'RESET_MANAGE_GAME_ERROR_MESSAGE';

/**
 * Screens
 */
export const FETCH_CACHE_SCREENS = 'FETCH_CACHE_SCREENS';
export const FETCH_SCREENS = 'FETCH_SCREENS';
export const FETCH_SCREENS_SUCCESS = 'FETCH_SCREENS_SUCCESS';
export const FETCH_SCREENS_FAILURE = 'FETCH_SCREENS_FAILURE';
export const RESET_SCREENS_ERROR = 'RESET_SCREENS_ERROR';

export const UPDATE_SCREEN_ORIENTATION = 'UPDATE_SCREEN_ORIENTATION';
export const UPDATE_SCREEN_ORIENTATION_SUCCESS = 'UPDATE_SCREEN_ORIENTATION_SUCCESS';
export const UPDATE_SCREEN_ORIENTATION_FAILURE = 'UPDATE_SCREEN_ORIENTATION_FAILURE';

export const TOGGLE_SCREEN_STATE = 'TOGGLE_SCREEN_STATE';
export const TOGGLE_SCREEN_STATE_SUCCESS = 'TOGGLE_SCREEN_STATE_SUCCESS';
export const TOGGLE_SCREEN_STATE_FAILURE = 'TOGGLE_SCREEN_STATE_FAILURE';

export const DELETE_SCREEN = 'DELETE_SCREEN';
export const DELETE_SCREEN_SUCCESS = 'DELETE_SCREEN_SUCCESS';
export const DELETE_SCREEN_FAILURE = 'DELETE_SCREEN_FAILURE';

export const FETCH_CACHE_SCREEN_CAMPAIGN_REQUESTS = 'FETCH_CACHE_SCREEN_CAMPAIGN_REQUESTS';
export const FETCH_SCREEN_CAMPAIGN_REQUESTS = 'FETCH_SCREEN_CAMPAIGN_REQUESTS';
export const FETCH_SCREEN_CAMPAIGN_REQUESTS_SUCCESS = 'FETCH_SCREEN_CAMPAIGN_REQUESTS_SUCCESS';
export const FETCH_SCREEN_CAMPAIGN_REQUESTS_FAILURE = 'FETCH_SCREEN_CAMPAIGN_REQUESTS_FAILURE';
export const RESET_SCREEN_CAMPAIGN_REQUESTS_ERROR = 'RESET_SCREEN_CAMPAIGN_REQUESTS_ERROR';

export const RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE = 'RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE';
export const RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE_SUCCESS = 'RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE_SUCCESS';
export const RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE_FAILURE = 'RESPOND_SCREEN_CAMPAIGN_REQUEST_STATE_FAILURE';

export const CONTROL_SCREEN_CAMPAIGN_REQUESTS = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS';
export const CONTROL_SCREEN_CAMPAIGN_REQUESTS_SUCCESS = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS_SUCCESS';
export const CONTROL_SCREEN_CAMPAIGN_REQUESTS_FAILURE = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS_FAILURE';
export const CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT';
export const CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT_SUCCESS = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT_SUCCESS';
export const CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT_FAILURE = 'CONTROL_SCREEN_CAMPAIGN_REQUESTS_SILENT_FAILURE';


/**
 * Showcase
 */
export const GENERATE_SCREEN_CODE = 'GENERATE_SCREEN_CODE';
export const GENERATE_SCREEN_CODE_SUCCESS = 'GENERATE_SCREEN_CODE_SUCCESS';
export const GENERATE_SCREEN_CODE_FAILURE = 'GENERATE_SCREEN_CODE_FAILURE';

export const FETCH_FUN_ACTIVITIES_SHOWCASE = 'FETCH_FUN_ACTIVITIES_SHOWCASE';
export const FETCH_FUN_ACTIVITIES_SHOWCASE_SUCCESS = 'FETCH_FUN_ACTIVITIES_SHOWCASE_SUCCESS';
export const FETCH_FUN_ACTIVITIES_SHOWCASE_FAILURE = 'FETCH_FUN_ACTIVITIES_SHOWCASE_FAILURE';
export const LOG_FUN_ACTIVITY_SHOWCASE = 'LOG_FUN_ACTIVITY_SHOWCASE';
export const LOG_FUN_ACTIVITY_SHOWCASE_SUCCESS = 'LOG_FUN_ACTIVITY_SHOWCASE_SUCCESS';
export const LOG_FUN_ACTIVITY_SHOWCASE_FAILURE = 'LOG_FUN_ACTIVITY_SHOWCASE_FAILURE';

export const POST_SHOWCASE_USER_INFO = 'POST_SHOWCASE_USER_INFO';
export const POST_SHOWCASE_USER_INFO_SUCCESS = 'POST_SHOWCASE_USER_INFO_SUCCESS';
export const POST_SHOWCASE_USER_INFO_FAILURE = 'POST_SHOWCASE_USER_INFO_FAILURE';

export const RESET_MANAGE_SCREEN_ERROR_MESSAGE = 'RESET_MANAGE_SCREEN_ERROR_MESSAGE';
export const MANAGE_SCREEN = 'MANAGE_SCREEN';
export const MANAGE_SCREEN_SUCCESS = 'MANAGE_SCREEN_SUCCESS';
export const MANAGE_SCREEN_FAILURE = 'MANAGE_SCREEN_FAILURE';

export const FETCH_SCREEN = 'FETCH_SCREEN';
export const FETCH_SCREEN_SUCCESS = 'FETCH_SCREEN_SUCCESS';
export const FETCH_SCREEN_FAILURE = 'FETCH_SCREEN_FAILURE';


/**
 * Branch Actions
 */
export const SHOW_BRANCH_MODAL = 'SHOW_BRANCH_MODAL';
export const HIDE_BRANCH_MODAL = 'HIDE_BRANCH_MODAL';
export const UPDATE_BRANCH_DATA = 'UPDATE_BRANCH_DATA';
export const VALIDATE_BRANCH_DATA = 'VALIDATE_BRANCH_DATA';
export const RESET_BRANCHES_DATA = 'RESET_BRANCHES_DATA';
export const RESET_ACTIVITY_BRANCHES_DATA = 'RESET_ACTIVITY_BRANCHES_DATA';
export const RESET_S_BRANCHES_DATA = 'RESET_S_BRANCHES_DATA';
export const RESET_S_ACTIVITY_BRANCHES_DATA = 'RESET_S_ACTIVITY_BRANCHES_DATA';

export const FETCH_BRANCHES = 'FETCH_BRANCHES';
export const FETCH_BRANCHES_SUCCESS = 'FETCH_BRANCHES_SUCCESS';
export const FETCH_BRANCHES_FAILURE = 'FETCH_BRANCHES_FAILURE';

export const FETCH_ACTIVITY_BRANCHES = 'FETCH_ACTIVITY_BRANCHES';
export const FETCH_ACTIVITY_BRANCHES_SUCCESS = 'FETCH_ACTIVITY_BRANCHES_SUCCESS';
export const FETCH_ACTIVITY_BRANCHES_FAILURE = 'FETCH_ACTIVITY_BRANCHES_FAILURE';

export const FETCH_S_BRANCHES = 'FETCH_S_BRANCHES';
export const FETCH_S_BRANCHES_SUCCESS = 'FETCH_S_BRANCHES_SUCCESS';
export const FETCH_S_BRANCHES_FAILURE = 'FETCH_S_BRANCHES_FAILURE';

export const FETCH_S_ACTIVITY_BRANCHES = 'FETCH_S_ACTIVITY_BRANCHES';
export const FETCH_S_ACTIVITY_BRANCHES_SUCCESS = 'FETCH_S_ACTIVITY_BRANCHES_SUCCESS';
export const FETCH_S_ACTIVITY_BRANCHES_FAILURE = 'FETCH_S_ACTIVITY_BRANCHES_FAILURE';

export const FETCH_BRANCH = 'FETCH_BRANCH';
export const FETCH_BRANCH_SUCCESS = 'FETCH_BRANCH_SUCCESS';
export const FETCH_BRANCH_FAILURE = 'FETCH_BRANCH_FAILURE';

export const CREATE_BRANCH = 'CREATE_BRANCH';
export const CREATE_BRANCH_SUCCESS = 'CREATE_BRANCH_SUCCESS';
export const CREATE_BRANCH_FAILURE = 'CREATE_BRANCH_FAILURE';

export const TOGGLE_BRANCH_STATE = 'TOGGLE_BRANCH_STATE';
export const TOGGLE_BRANCH_STATE_SUCCESS = 'TOGGLE_BRANCH_STATE_SUCCESS';
export const TOGGLE_BRANCH_STATE_FAILURE = 'TOGGLE_BRANCH_STATE_FAILURE';

export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const UPDATE_BRANCH_SUCCESS = 'UPDATE_BRANCH_SUCCESS';
export const UPDATE_BRANCH_FAILURE = 'UPDATE_BRANCH_FAILURE';

export const DELETE_BRANCH = 'DELETE_BRANCH';
export const DELETE_BRANCH_SUCCESS = 'DELETE_BRANCH_SUCCESS';
export const DELETE_BRANCH_FAILURE = 'DELETE_BRANCH_FAILURE';

/**
 * Gift Actions
 */
export const SHOW_GIFT_MODAL = 'SHOW_GIFT_MODAL';
export const HIDE_GIFT_MODAL = 'HIDE_GIFT_MODAL';
export const UPDATE_GIFT_DATA = 'UPDATE_GIFT_DATA';
export const VALIDATE_GIFT_DATA = 'VALIDATE_GIFT_DATA';
export const RESET_GIFTS_DATA = 'RESET_GIFTS_DATA';
export const RESET_S_GIFTS_DATA = 'RESET_S_GIFTS_DATA';

export const FETCH_GIFTS = 'FETCH_GIFTS';
export const FETCH_GIFTS_SUCCESS = 'FETCH_GIFTS_SUCCESS';
export const FETCH_GIFTS_FAILURE = 'FETCH_GIFTS_FAILURE';

export const FETCH_S_GIFTS = 'FETCH_S_GIFTS';
export const FETCH_S_GIFTS_SUCCESS = 'FETCH_S_GIFTS_SUCCESS';
export const FETCH_S_GIFTS_FAILURE = 'FETCH_S_GIFTS_FAILURE';

export const FETCH_GIFT = 'FETCH_GIFT';
export const FETCH_GIFT_SUCCESS = 'FETCH_GIFT_SUCCESS';
export const FETCH_GIFT_FAILURE = 'FETCH_GIFT_FAILURE';

export const CREATE_GIFT = 'CREATE_GIFT';
export const CREATE_GIFT_SUCCESS = 'CREATE_GIFT_SUCCESS';
export const CREATE_GIFT_FAILURE = 'CREATE_GIFT_FAILURE';

export const TOGGLE_GIFT_STATE = 'TOGGLE_GIFT_STATE';
export const TOGGLE_GIFT_STATE_SUCCESS = 'TOGGLE_GIFT_STATE_SUCCESS';
export const TOGGLE_GIFT_STATE_FAILURE = 'TOGGLE_GIFT_STATE_FAILURE';

export const UPDATE_GIFT = 'UPDATE_GIFT';
export const UPDATE_GIFT_SUCCESS = 'UPDATE_GIFT_SUCCESS';
export const UPDATE_GIFT_FAILURE = 'UPDATE_GIFT_FAILURE';

export const DELETE_GIFT = 'DELETE_GIFT';
export const DELETE_GIFT_SUCCESS = 'DELETE_GIFT_SUCCESS';
export const DELETE_GIFT_FAILURE = 'DELETE_GIFT_FAILURE';

/**
 * Loyalty Actions
 */
export const SHOW_EARN_POINT_FORMULA_MODAL = 'SHOW_EARN_POINT_FORMULA_MODAL';
export const HIDE_EARN_POINT_FORMULA_MODAL = 'HIDE_EARN_POINT_FORMULA_MODAL';
export const UPDATE_EARN_POINT_FORMULA_DATA = 'UPDATE_EARN_POINT_FORMULA_DATA';
export const VALIDATE_EARN_POINT_FORMULA_DATA = 'VALIDATE_EARN_POINT_FORMULA_DATA';
export const RESET_EARN_POINT_FORMULAS_DATA = 'RESET_EARN_POINT_FORMULAS_DATA';

export const UPDATE_ALLOW_BALANCE_TRANSFER = 'UPDATE_ALLOW_BALANCE_TRANSFER';
export const UPDATE_ALLOW_BALANCE_TRANSFER_SUCCESS = 'UPDATE_ALLOW_BALANCE_TRANSFER_SUCCESS';
export const UPDATE_ALLOW_BALANCE_TRANSFER_FAILURE = 'UPDATE_ALLOW_BALANCE_TRANSFER_FAILURE';

export const FETCH_EARN_POINT_FORMULAS = 'FETCH_EARN_POINT_FORMULAS';
export const FETCH_EARN_POINT_FORMULAS_SUCCESS = 'FETCH_EARN_POINT_FORMULAS_SUCCESS';
export const FETCH_EARN_POINT_FORMULAS_FAILURE = 'FETCH_EARN_POINT_FORMULAS_FAILURE';

export const FETCH_EARN_POINT_FORMULA = 'FETCH_EARN_POINT_FORMULA';
export const FETCH_EARN_POINT_FORMULA_SUCCESS = 'FETCH_EARN_POINT_FORMULA_SUCCESS';
export const FETCH_EARN_POINT_FORMULA_FAILURE = 'FETCH_EARN_POINT_FORMULA_FAILURE';

export const CREATE_EARN_POINT_FORMULA = 'CREATE_EARN_POINT_FORMULA';
export const CREATE_EARN_POINT_FORMULA_SUCCESS = 'CREATE_EARN_POINT_FORMULA_SUCCESS';
export const CREATE_EARN_POINT_FORMULA_FAILURE = 'CREATE_EARN_POINT_FORMULA_FAILURE';

export const TOGGLE_EARN_POINT_FORMULA_STATE = 'TOGGLE_EARN_POINT_FORMULA_STATE';
export const TOGGLE_EARN_POINT_FORMULA_STATE_SUCCESS = 'TOGGLE_EARN_POINT_FORMULA_STATE_SUCCESS';
export const TOGGLE_EARN_POINT_FORMULA_STATE_FAILURE = 'TOGGLE_EARN_POINT_FORMULA_STATE_FAILURE';

export const UPDATE_EARN_POINT_FORMULA = 'UPDATE_EARN_POINT_FORMULA';
export const UPDATE_EARN_POINT_FORMULA_SUCCESS = 'UPDATE_EARN_POINT_FORMULA_SUCCESS';
export const UPDATE_EARN_POINT_FORMULA_FAILURE = 'UPDATE_EARN_POINT_FORMULA_FAILURE';

export const DELETE_EARN_POINT_FORMULA = 'DELETE_EARN_POINT_FORMULA';
export const DELETE_EARN_POINT_FORMULA_SUCCESS = 'DELETE_EARN_POINT_FORMULA_SUCCESS';
export const DELETE_EARN_POINT_FORMULA_FAILURE = 'DELETE_EARN_POINT_FORMULA_FAILURE';

export const SHOW_REDEEM_POINT_FORMULA_MODAL = 'SHOW_REDEEM_POINT_FORMULA_MODAL';
export const HIDE_REDEEM_POINT_FORMULA_MODAL = 'HIDE_REDEEM_POINT_FORMULA_MODAL';
export const UPDATE_REDEEM_POINT_FORMULA_DATA = 'UPDATE_REDEEM_POINT_FORMULA_DATA';
export const VALIDATE_REDEEM_POINT_FORMULA_DATA = 'VALIDATE_REDEEM_POINT_FORMULA_DATA';
export const RESET_REDEEM_POINT_FORMULAS_DATA = 'RESET_REDEEM_POINT_FORMULAS_DATA';

export const FETCH_REDEEM_POINT_FORMULAS = 'FETCH_REDEEM_POINT_FORMULAS';
export const FETCH_REDEEM_POINT_FORMULAS_SUCCESS = 'FETCH_REDEEM_POINT_FORMULAS_SUCCESS';
export const FETCH_REDEEM_POINT_FORMULAS_FAILURE = 'FETCH_REDEEM_POINT_FORMULAS_FAILURE';

export const FETCH_REDEEM_POINT_FORMULA = 'FETCH_REDEEM_POINT_FORMULA';
export const FETCH_REDEEM_POINT_FORMULA_SUCCESS = 'FETCH_REDEEM_POINT_FORMULA_SUCCESS';
export const FETCH_REDEEM_POINT_FORMULA_FAILURE = 'FETCH_REDEEM_POINT_FORMULA_FAILURE';

export const CREATE_REDEEM_POINT_FORMULA = 'CREATE_REDEEM_POINT_FORMULA';
export const CREATE_REDEEM_POINT_FORMULA_SUCCESS = 'CREATE_REDEEM_POINT_FORMULA_SUCCESS';
export const CREATE_REDEEM_POINT_FORMULA_FAILURE = 'CREATE_REDEEM_POINT_FORMULA_FAILURE';

export const TOGGLE_REDEEM_POINT_FORMULA_STATE = 'TOGGLE_REDEEM_POINT_FORMULA_STATE';
export const TOGGLE_REDEEM_POINT_FORMULA_STATE_SUCCESS = 'TOGGLE_REDEEM_POINT_FORMULA_STATE_SUCCESS';
export const TOGGLE_REDEEM_POINT_FORMULA_STATE_FAILURE = 'TOGGLE_REDEEM_POINT_FORMULA_STATE_FAILURE';

export const UPDATE_REDEEM_POINT_FORMULA = 'UPDATE_REDEEM_POINT_FORMULA';
export const UPDATE_REDEEM_POINT_FORMULA_SUCCESS = 'UPDATE_REDEEM_POINT_FORMULA_SUCCESS';
export const UPDATE_REDEEM_POINT_FORMULA_FAILURE = 'UPDATE_REDEEM_POINT_FORMULA_FAILURE';

export const DELETE_REDEEM_POINT_FORMULA = 'DELETE_REDEEM_POINT_FORMULA';
export const DELETE_REDEEM_POINT_FORMULA_SUCCESS = 'DELETE_REDEEM_POINT_FORMULA_SUCCESS';
export const DELETE_REDEEM_POINT_FORMULA_FAILURE = 'DELETE_REDEEM_POINT_FORMULA_FAILURE';

/**
 * Employee Actions
 */
export const SHOW_EMPLOYEE_MODAL = 'SHOW_EMPLOYEE_MODAL';
export const HIDE_EMPLOYEE_MODAL = 'HIDE_EMPLOYEE_MODAL';
export const UPDATE_EMPLOYEE_DATA = 'UPDATE_EMPLOYEE_DATA';
export const VALIDATE_EMPLOYEE_DATA = 'VALIDATE_EMPLOYEE_DATA';
export const RESET_EMPLOYEES_DATA = 'RESET_EMPLOYEES_DATA';
export const RESET_S_EMPLOYEES_DATA = 'RESET_S_EMPLOYEES_DATA';

export const FETCH_EMPLOYEES = 'FETCH_EMPLOYEES';
export const FETCH_EMPLOYEES_SUCCESS = 'FETCH_EMPLOYEES_SUCCESS';
export const FETCH_EMPLOYEES_FAILURE = 'FETCH_EMPLOYEES_FAILURE';

export const FETCH_S_EMPLOYEES = 'FETCH_S_EMPLOYEES';
export const FETCH_S_EMPLOYEES_SUCCESS = 'FETCH_S_EMPLOYEES_SUCCESS';
export const FETCH_S_EMPLOYEES_FAILURE = 'FETCH_S_EMPLOYEES_FAILURE';

export const CREATE_EMPLOYEE_INVITE = 'CREATE_EMPLOYEE_INVITE';
export const CREATE_EMPLOYEE_INVITE_SUCCESS = 'CREATE_EMPLOYEE_INVITE_SUCCESS';
export const CREATE_EMPLOYEE_INVITE_FAILURE = 'CREATE_EMPLOYEE_INVITE_FAILURE';

export const INVITE_AGAIN_EMPLOYEE = 'INVITE_AGAIN_EMPLOYEE';
export const INVITE_AGAIN_EMPLOYEE_SUCCESS = 'INVITE_AGAIN_EMPLOYEE_SUCCESS';
export const INVITE_AGAIN_EMPLOYEE_FAILURE = 'INVITE_AGAIN_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE_INVITE = 'DELETE_EMPLOYEE_INVITE';
export const DELETE_EMPLOYEE_INVITE_SUCCESS = 'DELETE_EMPLOYEE_INVITE_SUCCESS';
export const DELETE_EMPLOYEE_INVITE_FAILURE = 'DELETE_EMPLOYEE_INVITE_FAILURE';

export const FETCH_EMPLOYEE = 'FETCH_EMPLOYEE';
export const FETCH_EMPLOYEE_SUCCESS = 'FETCH_EMPLOYEE_SUCCESS';
export const FETCH_EMPLOYEE_FAILURE = 'FETCH_EMPLOYEE_FAILURE';

export const TOGGLE_EMPLOYEE_STATE = 'TOGGLE_EMPLOYEE_STATE';
export const TOGGLE_EMPLOYEE_STATE_SUCCESS = 'TOGGLE_EMPLOYEE_STATE_SUCCESS';
export const TOGGLE_EMPLOYEE_STATE_FAILURE = 'TOGGLE_EMPLOYEE_STATE_FAILURE';

export const UPDATE_EMPLOYEE = 'UPDATE_EMPLOYEE';
export const UPDATE_EMPLOYEE_SUCCESS = 'UPDATE_EMPLOYEE_SUCCESS';
export const UPDATE_EMPLOYEE_FAILURE = 'UPDATE_EMPLOYEE_FAILURE';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

/**
 * Customer Actions
 */
export const RESET_USERS_DATA = 'RESET_USERS_DATA';
export const RESET_S_USERS_DATA = 'RESET_S_USERS_DATA';

export const FETCH_USERS = 'FETCH_USERS';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const FETCH_S_USERS = 'FETCH_S_USERS';
export const FETCH_S_USERS_SUCCESS = 'FETCH_S_USERS_SUCCESS';
export const FETCH_S_USERS_FAILURE = 'FETCH_S_USERS_FAILURE';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

/**
 * Posts Actions
 */
export const SHOW_POST_COMMENT_MODAL = 'SHOW_POST_COMMENT_MODAL';
export const HIDE_POST_COMMENT_MODAL = 'HIDE_POST_COMMENT_MODAL';
export const UPDATE_POST_COMMENT_DATA = 'UPDATE_POST_COMMENT_DATA';
export const VALIDATE_POST_COMMENT_DATA = 'VALIDATE_POST_COMMENT_DATA';
export const RESET_POSTS_DATA = 'RESET_POSTS_DATA';

export const FETCH_POSTS = 'FETCH_POSTS';
export const FETCH_POSTS_SUCCESS = 'FETCH_POSTS_SUCCESS';
export const FETCH_POSTS_FAILURE = 'FETCH_POSTS_FAILURE';

export const FETCH_POST = 'FETCH_POST';
export const FETCH_POST_SUCCESS = 'FETCH_POST_SUCCESS';
export const FETCH_POST_FAILURE = 'FETCH_POST_FAILURE';

export const CREATE_POST = 'CREATE_POST';
export const CREATE_POST_SUCCESS = 'CREATE_POST_SUCCESS';
export const CREATE_POST_FAILURE = 'CREATE_POST_FAILURE';

export const CREATE_POST_LIKE = 'CREATE_POST_LIKE';
export const CREATE_POST_LIKE_SUCCESS = 'CREATE_POST_LIKE_SUCCESS';
export const CREATE_POST_LIKE_FAILURE = 'CREATE_POST_LIKE_FAILURE';

export const UPDATE_POST = 'UPDATE_POST'
export const UPDATE_POST_SUCCESS = 'UPDATE_POST_SUCCESS';
export const UPDATE_POST_FAILURE = 'UPDATE_POST_FAILURE';

export const DELETE_POST = 'DELETE_POST';
export const DELETE_POST_SUCCESS = 'DELETE_POST_SUCCESS';
export const DELETE_POST_FAILURE = 'DELETE_POST_FAILURE';

export const FETCH_POST_COMMENTS = 'FETCH_POST_COMMENTS';
export const FETCH_POST_COMMENTS_SUCCESS = 'FETCH_POST_COMMENTS_SUCCESS';
export const FETCH_POST_COMMENTS_FAILURE = 'FETCH_POST_COMMENTS_FAILURE';

export const CREATE_POST_COMMENT = 'CREATE_POST_COMMENT';
export const CREATE_POST_COMMENT_SUCCESS = 'CREATE_POST_COMMENT_SUCCESS';
export const CREATE_POST_COMMENT_FAILURE = 'CREATE_POST_COMMENT_FAILURE';

export const DELETE_POST_COMMENT = 'DELETE_POST_COMMENT';
export const DELETE_POST_COMMENT_SUCCESS = 'DELETE_POST_COMMENT_SUCCESS';
export const DELETE_POST_COMMENT_FAILURE = 'DELETE_POST_COMMENT_FAILURE';

export const CREATE_POST_COMMENT_LIKE = 'CREATE_POST_COMMENT_LIKE';
export const CREATE_POST_COMMENT_LIKE_SUCCESS = 'CREATE_POST_COMMENT_LIKE_SUCCESS';
export const CREATE_POST_COMMENT_LIKE_FAILURE = 'CREATE_POST_COMMENT_LIKE_FAILURE';


export const SHOW_GEO_LOCATION_LOADING = 'SHOW_GEO_LOCATION_LOADING';
export const HIDE_GEO_LOCATION_LOADING = 'HIDE_GEO_LOCATION_LOADING';

/**
 * Activities Actions
 */
export const SHOW_FUN_ACTIVITY_LINK_COPIED_SNACKBAR = 'SHOW_FUN_ACTIVITY_LINK_COPIED_SNACKBAR';
export const RESET_FUN_ACTIVITIES_ERROR = 'RESET_FUN_ACTIVITIES_ERROR';
export const FETCH_CACHE_FUN_ACTIVITIES = 'FETCH_CACHE_FUN_ACTIVITIES';
export const FETCH_FUN_ACTIVITIES = 'FETCH_FUN_ACTIVITIES';
export const FETCH_FUN_ACTIVITIES_SUCCESS = 'FETCH_FUN_ACTIVITIES_SUCCESS';
export const FETCH_FUN_ACTIVITIES_FAILURE = 'FETCH_FUN_ACTIVITIES_FAILURE';
export const FETCH_FUN_ACTIVITY = 'FETCH_FUN_ACTIVITY';
export const FETCH_FUN_ACTIVITY_SUCCESS = 'FETCH_FUN_ACTIVITY_SUCCESS';
export const FETCH_FUN_ACTIVITY_FAILURE = 'FETCH_FUN_ACTIVITY_FAILURE';
export const FETCH_FUN_ACTIVITY_BLOCKED_LOADING = 'FETCH_FUN_ACTIVITY_BLOCKED_LOADING';
export const FETCH_FUN_ACTIVITY_BLOCKED_LOADING_SUCCESS = 'FETCH_FUN_ACTIVITY_BLOCKED_LOADING_SUCCESS';
export const FETCH_FUN_ACTIVITY_BLOCKED_LOADING_FAILURE = 'FETCH_FUN_ACTIVITY_BLOCKED_LOADING_FAILURE';
export const CACHE_LIST_DATA = 'CACHE_LIST_DATA';
export const RESET_CACHE_LIST_DATA = 'RESET_CACHE_LIST_DATA';
export const UPDATE_USER_WALLET = 'UPDATE_USER_WALLET';

export const FETCH_ACTIVITY_INSIGHT_CONFIGS = 'FETCH_ACTIVITY_INSIGHT_CONFIGS';
export const FETCH_ACTIVITY_INSIGHT_CONFIGS_SUCCESS = 'FETCH_ACTIVITY_INSIGHT_CONFIGS_SUCCESS';
export const FETCH_ACTIVITY_INSIGHT_CONFIGS_FAILURE = 'FETCH_ACTIVITY_INSIGHT_CONFIGS_FAILURE';

export const SORT_ACTIVITY_INSIGHT = 'SORT_ACTIVITY_INSIGHT';
export const TOGGLE_ZERO_IN_ACTIVITY_INSIGHT = 'TOGGLE_ZERO_IN_ACTIVITY_INSIGHT';
export const TOGGLE_SHOW_HIDE_QNS_IN_ACTIVITY_INSIGHT = 'TOGGLE_SHOW_HIDE_QNS_IN_ACTIVITY_INSIGHT';
export const RESET_ACTIVITY_INSIGHT = 'RESET_ACTIVITY_INSIGHT';
export const FETCH_ACTIVITY_INSIGHT = 'FETCH_ACTIVITY_INSIGHT';
export const FETCH_ACTIVITY_INSIGHT_SUCCESS = 'FETCH_ACTIVITY_INSIGHT_SUCCESS';
export const FETCH_ACTIVITY_INSIGHT_FAILURE = 'FETCH_ACTIVITY_INSIGHT_FAILURE';

export const SHOW_ACTIVITY_LINK_COPIED_SNACKBAR = 'SHOW_ACTIVITY_LINK_COPIED_SNACKBAR';
export const SHOW_ACTIVITY_MODAL = 'SHOW_ACTIVITY_MODAL';
export const HIDE_ACTIVITY_MODAL = 'HIDE_ACTIVITY_MODAL';
export const SHOW_DISPLAY_CRITERIA_MODAL = 'SHOW_DISPLAY_CRITERIA_MODAL';
export const HIDE_DISPLAY_CRITERIA_MODAL = 'HIDE_DISPLAY_CRITERIA_MODAL';
export const SHOW_ACTIVITY_ANSWERS_MODAL = 'SHOW_ACTIVITY_ANSWERS_MODAL';
export const HIDE_ACTIVITY_ANSWERS_MODAL = 'HIDE_ACTIVITY_ANSWERS_MODAL';
export const SHOW_ACTIVITY_RATINGS_MODAL = 'SHOW_ACTIVITY_RATINGS_MODAL';
export const HIDE_ACTIVITY_RATINGS_MODAL = 'HIDE_ACTIVITY_RATINGS_MODAL';
export const UPDATE_ACTIVITY_DATA = 'UPDATE_ACTIVITY_DATA';
export const VALIDATE_ACTIVITY_DATA = 'VALIDATE_ACTIVITY_DATA';
export const SHOW_ACTIVITY_COMMENT_MODAL = 'SHOW_ACTIVITY_COMMENT_MODAL';
export const HIDE_ACTIVITY_COMMENT_MODAL = 'HIDE_ACTIVITY_COMMENT_MODAL';
export const UPDATE_ACTIVITY_COMMENT_DATA = 'UPDATE_ACTIVITY_COMMENT_DATA';
export const VALIDATE_ACTIVITY_COMMENT_DATA = 'VALIDATE_ACTIVITY_COMMENT_DATA';
export const RESET_ACTIVITIES_DATA = 'RESET_ACTIVITIES_DATA';
export const RESET_S_ACTIVITIES_DATA = 'RESET_S_ACTIVITIES_DATA';
export const RESET_OFFERS_DATA = 'RESET_OFFERS_DATA';
export const RESET_S_OFFERS_DATA = 'RESET_S_OFFERS_DATA';

export const FETCH_CACHE_ACTIVITIES = 'FETCH_CACHE_ACTIVITIES';
export const FETCH_ACTIVITIES = 'FETCH_ACTIVITIES';
export const FETCH_ACTIVITIES_SUCCESS = 'FETCH_ACTIVITIES_SUCCESS';
export const FETCH_ACTIVITIES_FAILURE = 'FETCH_ACTIVITIES_FAILURE';

export const FETCH_S_ACTIVITIES = 'FETCH_S_ACTIVITIES';
export const FETCH_S_ACTIVITIES_SUCCESS = 'FETCH_S_ACTIVITIES_SUCCESS';
export const FETCH_S_ACTIVITIES_FAILURE = 'FETCH_S_ACTIVITIES_FAILURE';

export const FETCH_ACTIVITY_WINNERS = 'FETCH_ACTIVITY_WINNERS';
export const FETCH_ACTIVITY_WINNERS_SUCCESS = 'FETCH_ACTIVITY_WINNERS_SUCCESS';
export const FETCH_ACTIVITY_WINNERS_FAILURE = 'FETCH_ACTIVITY_WINNERS_FAILURE';

export const FETCH_FUN_WINNERS = 'FETCH_FUN_WINNERS';
export const FETCH_FUN_WINNERS_SUCCESS = 'FETCH_FUN_WINNERS_SUCCESS';
export const FETCH_FUN_WINNERS_FAILURE = 'FETCH_FUN_WINNERS_FAILURE';
export const FETCH_FUN_DUMMY_WINNERS = 'FETCH_FUN_DUMMY_WINNERS';
export const NOTIFY_ACTIVITY_WINNER = 'NOTIFY_ACTIVITY_WINNER';
export const NOTIFY_ACTIVITY_WINNER_SUCCESS = 'NOTIFY_ACTIVITY_WINNER_SUCCESS';
export const NOTIFY_ACTIVITY_WINNER_FAILURE = 'NOTIFY_ACTIVITY_WINNER_FAILURE';

export const FETCH_FUN_ANSWER_DISTRIBUTIONS = 'FETCH_FUN_ANSWER_DISTRIBUTIONS';
export const FETCH_FUN_ANSWER_DISTRIBUTIONS_SUCCESS = 'FETCH_FUN_ANSWER_DISTRIBUTIONS_SUCCESS';
export const FETCH_FUN_ANSWER_DISTRIBUTIONS_FAILURE = 'FETCH_FUN_ANSWER_DISTRIBUTIONS_FAILURE';
export const FETCH_DUMMY_FUN_ANSWER_DISTRIBUTIONS = 'FETCH_DUMMY_FUN_ANSWER_DISTRIBUTIONS';

export const ACTIVITY_REPORT = 'ACTIVITY_REPORT';
export const ACTIVITY_REPORT_SUCCESS = 'ACTIVITY_REPORT_SUCCESS';
export const ACTIVITY_REPORT_FAILURE = 'ACTIVITY_REPORT_FAILURE';

export const ACTIVITY_POST_REPORT = 'ACTIVITY_POST_REPORT';
export const ACTIVITY_POST_REPORT_SUCCESS = 'ACTIVITY_POST_REPORT_SUCCESS';
export const ACTIVITY_POST_REPORT_FAILURE = 'ACTIVITY_POST_REPORT_FAILURE';

export const ACTIVITY_POST_DELETE = 'ACTIVITY_POST_DELETE';
export const ACTIVITY_POST_DELETE_SUCCESS = 'ACTIVITY_POST_DELETE_SUCCESS';
export const ACTIVITY_POST_DELETE_FAILURE = 'ACTIVITY_POST_DELETE_FAILURE';

export const ACTIVITY_POST_ACTION_BG = 'ACTIVITY_POST_ACTION_BG';

export const ACTIVITY_ADD_POST = 'ACTIVITY_ADD_POST';

export const REPORT_ACTIVITY = 'REPORT_ACTIVITY';
export const REPORT_ACTIVITY_SUCCESS = 'REPORT_ACTIVITY_SUCCESS';
export const REPORT_ACTIVITY_FAILURE = 'REPORT_ACTIVITY_FAILURE';

export const FETCH_OFFERS = 'FETCH_OFFERS';
export const FETCH_OFFERS_SUCCESS = 'FETCH_OFFERS_SUCCESS';
export const FETCH_OFFERS_FAILURE = 'FETCH_OFFERS_FAILURE';

export const FETCH_S_OFFERS = 'FETCH_S_OFFERS';
export const FETCH_S_OFFERS_SUCCESS = 'FETCH_S_OFFERS_SUCCESS';
export const FETCH_S_OFFERS_FAILURE = 'FETCH_S_OFFERS_FAILURE';

export const FETCH_ACTIVITY = 'FETCH_ACTIVITY';
export const FETCH_ACTIVITY_SUCCESS = 'FETCH_ACTIVITY_SUCCESS';
export const FETCH_ACTIVITY_FAILURE = 'FETCH_ACTIVITY_FAILURE';
export const FETCH_ACTIVITY_NO_LOADING = 'FETCH_ACTIVITY_NO_LOADING';
export const FETCH_ACTIVITY_NO_LOADING_SUCCESS = 'FETCH_ACTIVITY_NO_LOADING_SUCCESS';
export const FETCH_ACTIVITY_NO_LOADING_FAILURE = 'FETCH_ACTIVITY_NO_LOADING_FAILURE';

export const FETCH_CACHE_ACTIVITY_PLAN_RECHARGES = 'FETCH_CACHE_ACTIVITY_PLAN_RECHARGES';
export const FETCH_ACTIVITY_PLAN_RECHARGES = 'FETCH_ACTIVITY_PLAN_RECHARGES';
export const FETCH_ACTIVITY_PLAN_RECHARGES_SUCCESS = 'FETCH_ACTIVITY_PLAN_RECHARGES_SUCCESS';
export const FETCH_ACTIVITY_PLAN_RECHARGES_FAILURE = 'FETCH_ACTIVITY_PLAN_RECHARGES_FAILURE';
export const FETCH_ACTIVITY_PLAN_RECHARGE = 'FETCH_ACTIVITY_PLAN_RECHARGE';
export const FETCH_ACTIVITY_PLAN_RECHARGE_SUCCESS = 'FETCH_ACTIVITY_PLAN_RECHARGE_SUCCESS';
export const DOWNLOAD_ACTIVITY_PLAN_RECHARGE = 'DOWNLOAD_ACTIVITY_PLAN_RECHARGE';
export const DELETE_ACTIVITY_PLAN_RECHARGE = 'DELETE_ACTIVITY_PLAN_RECHARGE';

export const FETCH_CACHE_BUSINESSES_WALLETS = 'FETCH_CACHE_BUSINESSES_WALLETS';
export const FETCH_BUSINESSES_WALLETS = 'FETCH_BUSINESSES_WALLETS';
export const FETCH_BUSINESSES_WALLETS_SUCCESS = 'FETCH_BUSINESSES_WALLETS_SUCCESS';
export const FETCH_BUSINESSES_WALLETS_FAILURE = 'FETCH_BUSINESSES_WALLETS_FAILURE';

export const FETCH_ACTIVITY_WIN_REWARDS = 'FETCH_ACTIVITY_WIN_REWARDS';
export const FETCH_ACTIVITY_WIN_REWARDS_SUCCESS = 'FETCH_ACTIVITY_WIN_REWARDS_SUCCESS';

export const FETCH_ACTIVITY_ANSWERS = 'FETCH_ACTIVITY_ANSWERS';
export const FETCH_ACTIVITY_ANSWERS_SUCCESS = 'FETCH_ACTIVITY_ANSWERS_SUCCESS';
export const FETCH_ACTIVITY_ANSWERS_FAILURE = 'FETCH_ACTIVITY_ANSWERS_FAILURE';

export const FETCH_ACTIVITY_RATINGS = 'FETCH_ACTIVITY_RATINGS';
export const FETCH_ACTIVITY_RATINGS_SUCCESS = 'FETCH_ACTIVITY_RATINGS_SUCCESS';
export const FETCH_ACTIVITY_RATINGS_FAILURE = 'FETCH_ACTIVITY_RATINGS_FAILURE';

export const CREATE_ACTIVITY = 'CREATE_ACTIVITY';
export const CREATE_ACTIVITY_SUCCESS = 'CREATE_ACTIVITY_SUCCESS';
export const CREATE_ACTIVITY_FAILURE = 'CREATE_ACTIVITY_FAILURE';

export const RESET_MANAGE_ACTIVITY_ERROR_MESSAGE = 'RESET_MANAGE_ACTIVITY_ERROR_MESSAGE';
export const MANAGE_ACTIVITY = 'MANAGE_ACTIVITY';
export const MANAGE_ACTIVITY_SUCCESS = 'MANAGE_ACTIVITY_SUCCESS';
export const MANAGE_ACTIVITY_FAILURE = 'MANAGE_ACTIVITY_FAILURE';

export const GET_ACTIVITY_REVIEWS = 'GET_ACTIVITY_REVIEWS';
export const GET_ACTIVITY_REVIEWS_SUCCESS = 'GET_ACTIVITY_REVIEWS_SUCCESS';
export const GET_ACTIVITY_REVIEWS_FAILURE = 'GET_ACTIVITY_REVIEWS_FAILURE';
export const MAKE_ACTION_IN_ACTIVITY = 'MAKE_ACTION_IN_ACTIVITY';
export const MAKE_ACTION_IN_ACTIVITY_SUCCESS = 'MAKE_ACTION_IN_ACTIVITY_SUCCESS';
export const MAKE_ACTION_IN_ACTIVITY_FAILURE = 'MAKE_ACTION_IN_ACTIVITY_FAILURE';
export const MAKE_ACTION_IN_ACTIVITY_BLOCKING = 'MAKE_ACTION_IN_ACTIVITY_BLOCKING';
export const MAKE_ACTION_IN_ACTIVITY_BLOCKING_SUCCESS = 'MAKE_ACTION_IN_ACTIVITY_BLOCKING_SUCCESS';
export const MAKE_ACTION_IN_ACTIVITY_BLOCKING_FAILURE = 'MAKE_ACTION_IN_ACTIVITY_BLOCKING_FAILURE';

export const ADD_REWARD_IN_ACTIVITY = 'ADD_REWARD_IN_ACTIVITY';
export const ADD_REWARD_IN_ACTIVITY_SUCCESS = 'ADD_REWARD_IN_ACTIVITY_SUCCESS';
export const ADD_REWARD_IN_ACTIVITY_FAILURE = 'ADD_REWARD_IN_ACTIVITY_FAILURE';

export const RESET_MANAGE_ACTIVITY_TARGETING_ERROR_MESSAGE = 'RESET_MANAGE_ACTIVITY_TARGETING_ERROR_MESSAGE';
export const MANAGE_ACTIVITY_TARGETING = 'MANAGE_ACTIVITY_TARGETING';
export const MANAGE_ACTIVITY_TARGETING_SUCCESS = 'MANAGE_ACTIVITY_TARGETING_SUCCESS';
export const MANAGE_ACTIVITY_TARGETING_FAILURE = 'MANAGE_ACTIVITY_TARGETING_FAILURE';

export const RESET_ACTIVITIES_ERROR = 'RESET_ACTIVITIES_ERROR';

export const TOGGLE_ACTIVITY_STATE = 'TOGGLE_ACTIVITY_STATE';
export const TOGGLE_ACTIVITY_STATE_SUCCESS = 'TOGGLE_ACTIVITY_STATE_SUCCESS';
export const TOGGLE_ACTIVITY_STATE_FAILURE = 'TOGGLE_ACTIVITY_STATE_FAILURE';

export const CREATE_ACTIVITY_LIKE = 'CREATE_ACTIVITY_LIKE';
export const CREATE_ACTIVITY_LIKE_SUCCESS = 'CREATE_ACTIVITY_LIKE_SUCCESS';
export const CREATE_ACTIVITY_LIKE_FAILURE = 'CREATE_ACTIVITY_LIKE_FAILURE';

export const UPDATE_ACTIVITY = 'UPDATE_ACTIVITY'
export const UPDATE_ACTIVITY_SUCCESS = 'UPDATE_ACTIVITY_SUCCESS';
export const UPDATE_ACTIVITY_FAILURE = 'UPDATE_ACTIVITY_FAILURE';

export const UPDATE_ACTIVITY_DISPLAY_CRITERIA = 'UPDATE_ACTIVITY_DISPLAY_CRITERIA'
export const UPDATE_ACTIVITY_DISPLAY_CRITERIA_SUCCESS = 'UPDATE_ACTIVITY_DISPLAY_CRITERIA_SUCCESS';
export const UPDATE_ACTIVITY_DISPLAY_CRITERIA_FAILURE = 'UPDATE_ACTIVITY_DISPLAY_CRITERIA_FAILURE';

export const DELETE_ACTIVITY = 'DELETE_ACTIVITY';
export const DELETE_ACTIVITY_SUCCESS = 'DELETE_ACTIVITY_SUCCESS';
export const DELETE_ACTIVITY_FAILURE = 'DELETE_ACTIVITY_FAILURE';

export const DECLARE_ACTIVITY_WINNERS = 'DECLARE_ACTIVITY_WINNERS';
export const DECLARE_ACTIVITY_WINNERS_SUCCESS = 'DECLARE_ACTIVITY_WINNERS_SUCCESS';
export const DECLARE_ACTIVITY_WINNERS_FAILURE = 'DECLARE_ACTIVITY_WINNERS_FAILURE';

export const DOWNLOAD_ACTIVITY_REVIEW = 'DOWNLOAD_ACTIVITY_REVIEW';

export const FETCH_ACTIVITY_COMMENTS = 'FETCH_ACTIVITY_COMMENTS';
export const FETCH_ACTIVITY_COMMENTS_SUCCESS = 'FETCH_ACTIVITY_COMMENTS_SUCCESS';
export const FETCH_ACTIVITY_COMMENTS_FAILURE = 'FETCH_ACTIVITY_COMMENTS_FAILURE';

export const CREATE_ACTIVITY_COMMENT = 'CREATE_ACTIVITY_COMMENT';
export const CREATE_ACTIVITY_COMMENT_SUCCESS = 'CREATE_ACTIVITY_COMMENT_SUCCESS';
export const CREATE_ACTIVITY_COMMENT_FAILURE = 'CREATE_ACTIVITY_COMMENT_FAILURE';

export const DELETE_ACTIVITY_COMMENT = 'DELETE_ACTIVITY_COMMENT';
export const DELETE_ACTIVITY_COMMENT_SUCCESS = 'DELETE_ACTIVITY_COMMENT_SUCCESS';
export const DELETE_ACTIVITY_COMMENT_FAILURE = 'DELETE_ACTIVITY_COMMENT_FAILURE';

export const CREATE_ACTIVITY_COMMENT_LIKE = 'CREATE_ACTIVITY_COMMENT_LIKE';
export const CREATE_ACTIVITY_COMMENT_LIKE_SUCCESS = 'CREATE_ACTIVITY_COMMENT_LIKE_SUCCESS';
export const CREATE_ACTIVITY_COMMENT_LIKE_FAILURE = 'CREATE_ACTIVITY_COMMENT_LIKE_FAILURE';

export const FETCH_ACTIVITY_QUESTIONS = 'FETCH_ACTIVITY_QUESTIONS';
export const FETCH_ACTIVITY_QUESTIONS_SUCCESS = 'FETCH_ACTIVITY_QUESTIONS_SUCCESS';
export const FETCH_ACTIVITY_QUESTIONS_FAILURE = 'FETCH_ACTIVITY_QUESTIONS_FAILURE';
export const RESET_ACTIVITY_QUESTIONS = 'RESET_ACTIVITY_QUESTIONS';

export const FETCH_ACTIVITY_QUESTION_OPTIONS = 'FETCH_ACTIVITY_QUESTION_OPTIONS';
export const FETCH_ACTIVITY_QUESTION_OPTIONS_SUCCESS = 'FETCH_ACTIVITY_QUESTION_OPTIONS_SUCCESS';
export const FETCH_ACTIVITY_QUESTION_OPTIONS_FAILURE = 'FETCH_ACTIVITY_QUESTION_OPTIONS_FAILURE';

export const FETCH_ACTIVITY_QUESTIONS_DROP_DOWN = 'FETCH_ACTIVITY_QUESTIONS_DROP_DOWN';
export const FETCH_ACTIVITY_QUESTIONS_DROP_DOWN_SUCCESS = 'FETCH_ACTIVITY_QUESTIONS_DROP_DOWN_SUCCESS';
export const FETCH_ACTIVITY_QUESTIONS_DROP_DOWN_FAILURE = 'FETCH_ACTIVITY_QUESTIONS_DROP_DOWN_FAILURE';

export const FETCH_S_QUESTIONS = 'FETCH_S_QUESTIONS';
export const FETCH_S_QUESTIONS_SUCCESS = 'FETCH_S_QUESTIONS_SUCCESS';
export const FETCH_S_QUESTIONS_FAILURE = 'FETCH_S_QUESTIONS_FAILURE';
export const RESET_S_QUESTIONS = 'RESET_S_QUESTIONS';

export const FETCH_S_QUESTION_OPTIONS = 'FETCH_S_QUESTION_OPTIONS';
export const FETCH_S_QUESTION_OPTIONS_SUCCESS = 'FETCH_S_QUESTION_OPTIONS_SUCCESS';
export const FETCH_S_QUESTION_OPTIONS_FAILURE = 'FETCH_S_QUESTION_OPTIONS_FAILURE';

export const FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS = 'FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS';
export const FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS_SUCCESS = 'FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS_SUCCESS';
export const FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS_FAILURE = 'FETCH_FOCUS_AREA_ACTIVITY_QUESTIONS_FAILURE';
export const RESET_FOCUS_AREA_ACTIVITY_QUESTIONS = 'RESET_FOCUS_AREA_ACTIVITY_QUESTIONS';

/**
 * Chats
 */
export const SHOW_EMPLOYEE_CHAT_MODAL = 'SHOW_EMPLOYEE_CHAT_MODAL';
export const HIDE_EMPLOYEE_CHAT_MODAL = 'HIDE_EMPLOYEE_CHAT_MODAL';
export const UPDATE_EMPLOYEE_CHAT_DATA = 'UPDATE_EMPLOYEE_CHAT_DATA';
export const VALIDATE_EMPLOYEE_CHAT_DATA = 'VALIDATE_EMPLOYEE_CHAT_DATA';
export const RESET_EMPLOYEE_CHATS_DATA = 'RESET_EMPLOYEE_CHATS_DATA';

export const FETCH_EMPLOYEE_CHATS = 'FETCH_EMPLOYEE_CHATS';
export const FETCH_EMPLOYEE_CHATS_SUCCESS = 'FETCH_EMPLOYEE_CHATS_SUCCESS';
export const FETCH_EMPLOYEE_CHATS_FAILURE = 'FETCH_EMPLOYEE_CHATS_FAILURE';

export const FETCH_EMPLOYEE_MESSAGES = 'FETCH_EMPLOYEE_MESSAGES';
export const FETCH_EMPLOYEE_MESSAGES_SUCCESS = 'FETCH_EMPLOYEE_MESSAGES_SUCCESS';
export const FETCH_EMPLOYEE_MESSAGES_FAILURE = 'FETCH_EMPLOYEE_MESSAGES_FAILURE';

export const EMPLOYEE_BROADCAST = 'EMPLOYEE_BROADCAST';
export const EMPLOYEE_BROADCAST_SUCCESS = 'EMPLOYEE_BROADCAST_SUCCESS';
export const EMPLOYEE_BROADCAST_FAILURE = 'EMPLOYEE_BROADCAST_FAILURE';

export const CREATE_EMPLOYEE_MESSAGE = 'CREATE_EMPLOYEE_MESSAGE';
export const CREATE_EMPLOYEE_MESSAGE_SUCCESS = 'CREATE_EMPLOYEE_MESSAGE_SUCCESS';
export const CREATE_EMPLOYEE_MESSAGE_FAILURE = 'CREATE_EMPLOYEE_MESSAGE_FAILURE';

export const DELETE_EMPLOYEE_MESSAGE = 'DELETE_EMPLOYEE_MESSAGE';
export const DELETE_EMPLOYEE_MESSAGE_SUCCESS = 'DELETE_EMPLOYEE_MESSAGE_SUCCESS';
export const DELETE_EMPLOYEE_MESSAGE_FAILURE = 'DELETE_EMPLOYEE_MESSAGE_FAILURE';

export const SHOW_CUSTOMER_CHAT_MODAL = 'SHOW_CUSTOMER_CHAT_MODAL';
export const HIDE_CUSTOMER_CHAT_MODAL = 'HIDE_CUSTOMER_CHAT_MODAL';
export const UPDATE_CUSTOMER_CHAT_DATA = 'UPDATE_CUSTOMER_CHAT_DATA';
export const VALIDATE_CUSTOMER_CHAT_DATA = 'VALIDATE_CUSTOMER_CHAT_DATA';
export const RESET_CUSTOMER_CHATS_DATA = 'RESET_CUSTOMER_CHATS_DATA';

export const FETCH_CUSTOMER_CHATS = 'FETCH_CUSTOMER_CHATS';
export const FETCH_CUSTOMER_CHATS_SUCCESS = 'FETCH_CUSTOMER_CHATS_SUCCESS';
export const FETCH_CUSTOMER_CHATS_FAILURE = 'FETCH_CUSTOMER_CHATS_FAILURE';

export const FETCH_CUSTOMER_MESSAGES = 'FETCH_CUSTOMER_MESSAGES';
export const FETCH_CUSTOMER_MESSAGES_SUCCESS = 'FETCH_CUSTOMER_MESSAGES_SUCCESS';
export const FETCH_CUSTOMER_MESSAGES_FAILURE = 'FETCH_CUSTOMER_MESSAGES_FAILURE';

export const CREATE_CUSTOMER_MESSAGE = 'CREATE_CUSTOMER_MESSAGE';
export const CREATE_CUSTOMER_MESSAGE_SUCCESS = 'CREATE_CUSTOMER_MESSAGE_SUCCESS';
export const CREATE_CUSTOMER_MESSAGE_FAILURE = 'CREATE_CUSTOMER_MESSAGE_FAILURE';

export const DELETE_CUSTOMER_MESSAGE = 'DELETE_CUSTOMER_MESSAGE';
export const DELETE_CUSTOMER_MESSAGE_SUCCESS = 'DELETE_CUSTOMER_MESSAGE_SUCCESS';
export const DELETE_CUSTOMER_MESSAGE_FAILURE = 'DELETE_CUSTOMER_MESSAGE_FAILURE';

/**
 * Insights
 */
export const RESET_INSIGHTS_DATA = 'RESET_INSIGHTS_DATA';

export const FETCH_INSIGHTS_EMPLOYEES = 'FETCH_INSIGHTS_EMPLOYEES';
export const FETCH_INSIGHTS_EMPLOYEES_SUCCESS = 'FETCH_INSIGHTS_EMPLOYEES_SUCCESS';
export const FETCH_INSIGHTS_EMPLOYEES_FAILURE = 'FETCH_INSIGHTS_EMPLOYEES_FAILURE';

export const FETCH_INSIGHTS_PRODUCTS = 'FETCH_INSIGHTS_PRODUCTS';
export const FETCH_INSIGHTS_PRODUCTS_SUCCESS = 'FETCH_INSIGHTS_PRODUCTS_SUCCESS';
export const FETCH_INSIGHTS_PRODUCTS_FAILURE = 'FETCH_INSIGHTS_PRODUCTS_FAILURE';

export const FETCH_INSIGHTS_PROFILE = 'FETCH_INSIGHTS_PROFILE';
export const FETCH_INSIGHTS_PROFILE_SUCCESS = 'FETCH_INSIGHTS_PROFILE_SUCCESS';
export const FETCH_INSIGHTS_PROFILE_FAILURE = 'FETCH_INSIGHTS_PROFILE_FAILURE';

export const FETCH_INSIGHTS_SALES = 'FETCH_INSIGHTS_SALES';
export const FETCH_INSIGHTS_SALES_SUCCESS = 'FETCH_INSIGHTS_SALES_SUCCESS';
export const FETCH_INSIGHTS_SALES_FAILURE = 'FETCH_INSIGHTS_SALES_FAILURE';

export const FETCH_INSIGHTS_CAMPAIGNS = 'FETCH_INSIGHTS_CAMPAIGNS';
export const FETCH_INSIGHTS_CAMPAIGNS_SUCCESS = 'FETCH_INSIGHTS_CAMPAIGNS_SUCCESS';
export const FETCH_INSIGHTS_CAMPAIGNS_FAILURE = 'FETCH_INSIGHTS_CAMPAIGNS_FAILURE';

export const FETCH_FOCUS_AREA = 'FETCH_FOCUS_AREA';
export const FETCH_FOCUS_AREA_SUCCESS = 'FETCH_FOCUS_AREA_SUCCESS';
export const FETCH_FOCUS_AREA_FAILURE = 'FETCH_FOCUS_AREA_FAILURE';

export const FETCH_INSIGHT_CHARTS = 'FETCH_INSIGHT_CHARTS';
export const FETCH_INSIGHT_CHARTS_SUCCESS = 'FETCH_INSIGHT_CHARTS_SUCCESS';
export const FETCH_INSIGHT_CHARTS_FAILURE = 'FETCH_INSIGHT_CHARTS_FAILURE';

export const FETCH_INSIGHT_FILTERS = 'FETCH_INSIGHT_FILTERS';
export const FETCH_INSIGHT_FILTERS_SUCCESS = 'FETCH_INSIGHT_FILTERS_SUCCESS';
export const FETCH_INSIGHT_FILTERS_FAILURE = 'FETCH_INSIGHT_FILTERS_FAILURE';

/**
 * Transactions
 */
export const RESET_TRANSACTIONS_DATA = 'RESET_TRANSACTIONS_DATA';

export const FETCH_TRANSACTIONS = 'FETCH_TRANSACTIONS';
export const FETCH_TRANSACTIONS_SUCCESS = 'FETCH_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSACTIONS_FAILURE = 'FETCH_TRANSACTIONS_FAILURE';

export const FETCH_TRANSACTION = 'FETCH_TRANSACTION';
export const FETCH_TRANSACTION_SUCCESS = 'FETCH_TRANSACTION_SUCCESS';
export const FETCH_TRANSACTION_FAILURE = 'FETCH_TRANSACTION_FAILURE';

/**
 * Transfer Transactions
 */
export const RESET_TRANSFER_TRANSACTIONS_DATA = 'RESET_TRANSFER_TRANSACTIONS_DATA';

export const FETCH_TRANSFER_TRANSACTIONS = 'FETCH_TRANSFER_TRANSACTIONS';
export const FETCH_TRANSFER_TRANSACTIONS_SUCCESS = 'FETCH_TRANSFER_TRANSACTIONS_SUCCESS';
export const FETCH_TRANSFER_TRANSACTIONS_FAILURE = 'FETCH_TRANSFER_TRANSACTIONS_FAILURE';

/**
 * User Balances
 */
export const RESET_USER_BALANCES_DATA = 'RESET_USER_BALANCES_DATA';

export const FETCH_USER_BALANCES = 'FETCH_USER_BALANCES';
export const FETCH_USER_BALANCES_SUCCESS = 'FETCH_USER_BALANCES_SUCCESS';
export const FETCH_USER_BALANCES_FAILURE = 'FETCH_USER_BALANCES_FAILURE';

export const FETCH_USER_BALANCE = 'FETCH_USER_BALANCE';
export const FETCH_USER_BALANCE_SUCCESS = 'FETCH_USER_BALANCE_SUCCESS';
export const FETCH_USER_BALANCE_FAILURE = 'FETCH_USER_BALANCE_FAILURE';

/**
 * System User Actions
 */
export const SHOW_SYSTEM_USER_MODAL = 'SHOW_SYSTEM_USER_MODAL';
export const HIDE_SYSTEM_USER_MODAL = 'HIDE_SYSTEM_USER_MODAL';
export const UPDATE_SYSTEM_USER_DATA = 'UPDATE_SYSTEM_USER_DATA';
export const VALIDATE_SYSTEM_USER_DATA = 'VALIDATE_SYSTEM_USER_DATA';
export const RESET_SYSTEM_USERS_DATA = 'RESET_SYSTEM_USERS_DATA';

export const FETCH_SYSTEM_USERS = 'FETCH_SYSTEM_USERS';
export const FETCH_SYSTEM_USERS_SUCCESS = 'FETCH_SYSTEM_USERS_SUCCESS';
export const FETCH_SYSTEM_USERS_FAILURE = 'FETCH_SYSTEM_USERS_FAILURE';

export const CREATE_SYSTEM_USER = 'CREATE_SYSTEM_USER';
export const CREATE_SYSTEM_USER_SUCCESS = 'CREATE_SYSTEM_USER_SUCCESS';
export const CREATE_SYSTEM_USER_FAILURE = 'CREATE_SYSTEM_USER_FAILURE';

export const FETCH_SYSTEM_USER = 'FETCH_SYSTEM_USER';
export const FETCH_SYSTEM_USER_SUCCESS = 'FETCH_SYSTEM_USER_SUCCESS';
export const FETCH_SYSTEM_USER_FAILURE = 'FETCH_SYSTEM_USER_FAILURE';

export const TOGGLE_SYSTEM_USER_STATE = 'TOGGLE_SYSTEM_USER_STATE';
export const TOGGLE_SYSTEM_USER_STATE_SUCCESS = 'TOGGLE_SYSTEM_USER_STATE_SUCCESS';
export const TOGGLE_SYSTEM_USER_STATE_FAILURE = 'TOGGLE_SYSTEM_USER_STATE_FAILURE';

export const UPDATE_SYSTEM_USER = 'UPDATE_SYSTEM_USER';
export const UPDATE_SYSTEM_USER_SUCCESS = 'UPDATE_SYSTEM_USER_SUCCESS';
export const UPDATE_SYSTEM_USER_FAILURE = 'UPDATE_SYSTEM_USER_FAILURE';

export const DELETE_SYSTEM_USER = 'DELETE_SYSTEM_USER';
export const DELETE_SYSTEM_USER_SUCCESS = 'DELETE_SYSTEM_USER_SUCCESS';
export const DELETE_SYSTEM_USER_FAILURE = 'DELETE_SYSTEM_USER_FAILURE';

/**
 * Product Categories Actions
 */
export const SHOW_PRODUCT_CATEGORY_MODAL = 'SHOW_PRODUCT_CATEGORY_MODAL';
export const HIDE_PRODUCT_CATEGORY_MODAL = 'HIDE_PRODUCT_CATEGORY_MODAL';
export const UPDATE_PRODUCT_CATEGORY_DATA = 'UPDATE_PRODUCT_CATEGORY_DATA';
export const VALIDATE_PRODUCT_CATEGORY_DATA = 'VALIDATE_PRODUCT_CATEGORY_DATA';
export const RESET_PRODUCT_CATEGORIES_DATA = 'RESET_PRODUCT_CATEGORIES_DATA';

export const FETCH_PRODUCT_CATEGORIES = 'FETCH_PRODUCT_CATEGORIES';
export const FETCH_PRODUCT_CATEGORIES_SUCCESS = 'FETCH_PRODUCT_CATEGORIES_SUCCESS';
export const FETCH_PRODUCT_CATEGORIES_FAILURE = 'FETCH_PRODUCT_CATEGORIES_FAILURE';

export const CREATE_PRODUCT_CATEGORY = 'CREATE_PRODUCT_CATEGORY';
export const CREATE_PRODUCT_CATEGORY_SUCCESS = 'CREATE_PRODUCT_CATEGORY_SUCCESS';
export const CREATE_PRODUCT_CATEGORY_FAILURE = 'CREATE_PRODUCT_CATEGORY_FAILURE';

export const FETCH_PRODUCT_CATEGORY = 'FETCH_PRODUCT_CATEGORY';
export const FETCH_PRODUCT_CATEGORY_SUCCESS = 'FETCH_PRODUCT_CATEGORY_SUCCESS';
export const FETCH_PRODUCT_CATEGORY_FAILURE = 'FETCH_PRODUCT_CATEGORY_FAILURE';

export const TOGGLE_PRODUCT_CATEGORY_STATE = 'TOGGLE_PRODUCT_CATEGORY_STATE';
export const TOGGLE_PRODUCT_CATEGORY_STATE_SUCCESS = 'TOGGLE_PRODUCT_CATEGORY_STATE_SUCCESS';
export const TOGGLE_PRODUCT_CATEGORY_STATE_FAILURE = 'TOGGLE_PRODUCT_CATEGORY_STATE_FAILURE';

export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_FAILURE = 'UPDATE_PRODUCT_CATEGORY_FAILURE';

export const DELETE_PRODUCT_CATEGORY = 'DELETE_PRODUCT_CATEGORY';
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS';
export const DELETE_PRODUCT_CATEGORY_FAILURE = 'DELETE_PRODUCT_CATEGORY_FAILURE';

/**
 * Product Items Actions
 */
export const SHOW_PRODUCT_ITEM_MODAL = 'SHOW_PRODUCT_ITEM_MODAL';
export const HIDE_PRODUCT_ITEM_MODAL = 'HIDE_PRODUCT_ITEM_MODAL';
export const UPDATE_PRODUCT_ITEM_DATA = 'UPDATE_PRODUCT_ITEM_DATA';
export const VALIDATE_PRODUCT_ITEM_DATA = 'VALIDATE_PRODUCT_ITEM_DATA';
export const RESET_PRODUCT_ITEMS_DATA = 'RESET_PRODUCT_ITEMS_DATA';

export const FETCH_PRODUCT_ITEMS = 'FETCH_PRODUCT_ITEMS';
export const FETCH_PRODUCT_ITEMS_SUCCESS = 'FETCH_PRODUCT_ITEMS_SUCCESS';
export const FETCH_PRODUCT_ITEMS_FAILURE = 'FETCH_PRODUCT_ITEMS_FAILURE';

export const CREATE_PRODUCT_ITEM = 'CREATE_PRODUCT_ITEM';
export const CREATE_PRODUCT_ITEM_SUCCESS = 'CREATE_PRODUCT_ITEM_SUCCESS';
export const CREATE_PRODUCT_ITEM_FAILURE = 'CREATE_PRODUCT_ITEM_FAILURE';

export const FETCH_PRODUCT_ITEM = 'FETCH_PRODUCT_ITEM';
export const FETCH_PRODUCT_ITEM_SUCCESS = 'FETCH_PRODUCT_ITEM_SUCCESS';
export const FETCH_PRODUCT_ITEM_FAILURE = 'FETCH_PRODUCT_ITEM_FAILURE';

export const TOGGLE_PRODUCT_ITEM_STATE = 'TOGGLE_PRODUCT_ITEM_STATE';
export const TOGGLE_PRODUCT_ITEM_STATE_SUCCESS = 'TOGGLE_PRODUCT_ITEM_STATE_SUCCESS';
export const TOGGLE_PRODUCT_ITEM_STATE_FAILURE = 'TOGGLE_PRODUCT_ITEM_STATE_FAILURE';

export const UPDATE_PRODUCT_ITEM = 'UPDATE_PRODUCT_ITEM';
export const UPDATE_PRODUCT_ITEM_SUCCESS = 'UPDATE_PRODUCT_ITEM_SUCCESS';
export const UPDATE_PRODUCT_ITEM_FAILURE = 'UPDATE_PRODUCT_ITEM_FAILURE';

export const DELETE_PRODUCT_ITEM = 'DELETE_PRODUCT_ITEM';
export const DELETE_PRODUCT_ITEM_SUCCESS = 'DELETE_PRODUCT_ITEM_SUCCESS';
export const DELETE_PRODUCT_ITEM_FAILURE = 'DELETE_PRODUCT_ITEM_FAILURE';

/**
 * Support Chat
 */
export const SHOW_SUPPORT_CHAT_MODAL = 'SHOW_SUPPORT_CHAT_MODAL';
export const HIDE_SUPPORT_CHAT_MODAL = 'HIDE_SUPPORT_CHAT_MODAL';

export const FETCH_SUPPORT_MESSAGES = 'FETCH_SUPPORT_MESSAGES';
export const FETCH_SUPPORT_MESSAGES_SUCCESS = 'FETCH_SUPPORT_MESSAGES_SUCCESS';
export const FETCH_SUPPORT_MESSAGES_FAILURE = 'FETCH_SUPPORT_MESSAGES_FAILURE';

export const CREATE_SUPPORT_MESSAGE = 'CREATE_SUPPORT_MESSAGE';
export const CREATE_SUPPORT_MESSAGE_SUCCESS = 'CREATE_SUPPORT_MESSAGE_SUCCESS';
export const CREATE_SUPPORT_MESSAGE_FAILURE = 'CREATE_SUPPORT_MESSAGE_FAILURE';

export const DELETE_SUPPORT_MESSAGE = 'DELETE_SUPPORT_MESSAGE';
export const DELETE_SUPPORT_MESSAGE_SUCCESS = 'DELETE_SUPPORT_MESSAGE_SUCCESS';
export const DELETE_SUPPORT_MESSAGE_FAILURE = 'DELETE_SUPPORT_MESSAGE_FAILURE';

/**
 * Nationalities action
 */
export const FETCH_NATIONALITIES = 'FETCH_NATIONALITIES';
export const FETCH_NATIONALITIES_SUCCESS = 'FETCH_NATIONALITIES_SUCCESS';
export const FETCH_NATIONALITIES_FAILURE = 'FETCH_NATIONALITIES_FAILURE';
