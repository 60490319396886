import {Scene} from 'phaser';

export class GTGPreloader extends Scene {
  constructor() {
    super('GTGPreloader');
  }

  init() {
    const gameWidth = this.game.config.width;
    const gameHeight = this.game.config.height;
    //  We loaded this image in our GFKBoot Scene, so we can display it here
    const bgImage = this.add.image(gameWidth / 2, gameHeight / 2, 'background');
    bgImage.setDisplaySize(gameWidth, gameHeight);

    const barWidth = gameWidth - 96;
    const innerBarWidth = barWidth - 8;

    //  A simple progress bar. This is the outline of the bar.
    this.add.rectangle(gameWidth / 2, gameHeight / 2, barWidth, 32).setStrokeStyle(1, 0xEEEEEE);

    //  This is the progress bar itself. It will increase in size from the left based on the % of progress.
    const bar = this.add.rectangle((gameWidth - innerBarWidth) / 2, gameHeight / 2, 4, 28, 0xEEEEEE);

    //  Use the 'progress' event emitted by the LoaderPlugin to update the loading bar
    this.load.on('progress', (progress) => {

      //  Update the progress bar (our bar is 464px wide, so 100% = 464px)
      bar.width = 4 + (innerBarWidth * progress);

    });
  }

  preload() {
    let bgAudioUrl = null;
    let carTopImageUrl = null;
    let bonusImageUrl = null;
    let adImageUrl = null;
    const options = this.game.options;
    if (options) {
      if (options.images && options.images.length > 0) {
        options.images.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              carTopImageUrl = item.url;
            } else if (item.number === 1) {
              bonusImageUrl = item.url;
            } else if (item.number === 2) {
              adImageUrl = item.url;
            }
          }
        });
      }
      if (options.audios && options.audios.length > 0) {
        options.audios.forEach((item) => {
          if (item.url && (item.url.startsWith('file:') || item.url.startsWith('blob:') || item.url.startsWith('http:') || item.url.startsWith('https:'))) {
            if (item.number === 0) {
              bgAudioUrl = item.url;
            }
          }
        });
      }
    }

    this.load.setPath('assets/gameTrafficGo');

    this.load.image('carTop', carTopImageUrl || 'carTop.png');
    this.load.image('raw_bonus', bonusImageUrl || 'bonus.png');
    this.load.image('adImage1', adImageUrl || 'adImage.png');
    this.load.audio('audio_bg', bgAudioUrl || 'audios/audio_bg.m4a');

    this.load.image('roadCurve', 'roadCurve.png');
    this.load.image('raw_car', 'car.png');
    this.load.image('carBlue', 'carBlue.png');
    this.load.image('carCop', 'carCop.png');
    this.load.image('pickupTruck', 'pickupTruck.png');
    this.load.image('truck', 'truck.png');
    this.load.image('crash', 'crash.png');
    this.load.image('plus', 'plus.png');
    this.load.image('finish', 'finish.png');

    this.load.audio('audio_play', 'audios/audio_play.mp3');
    this.load.audio('audio_crash', 'audios/audio_crash.mp3');
    this.load.audio('audio_bonus', 'audios/audio_bonus.mp3');
    this.load.audio('audio_game_end', 'audios/audio_game_end.wav');
    this.load.audio('audio_game_win', 'audios/audio_game_win.mp3');
  }

  create() {
    const renderTextureScorer = this.make.renderTexture({x: 0, y: 0, width: 160, height: 224}, false);
    const imageScorer = this.add.image(80, 80, 'raw_bonus')
      .setDisplaySize(160, 160)
      .setVisible(false);
    const imagePlus = this.add.image(43, 32, 'plus')
      .setDisplaySize(87, 64)
      .setVisible(false);
    renderTextureScorer.draw(imageScorer, 80, 140, 1);
    renderTextureScorer.draw(imagePlus, 80, 32, 1);
    renderTextureScorer.saveTexture('bonus');
    renderTextureScorer.destroy(true);

    const carWidth = 300 - 32;
    const carHeight = carWidth*1.8;
    const carTopSize = carWidth*.6;
    const renderTexture1 = this.make.renderTexture({x: 0, y: 0, width: carWidth, height: carHeight}, false);
    const image11 = this.add.image(0, 0, 'raw_car')
      .setDisplaySize(carWidth, carHeight)
      .setVisible(false);
    const image12 = this.add.image(0, 0, 'carTop')
      .setDisplaySize(carTopSize, carTopSize)
      .setVisible(false);
    renderTexture1.draw(image11, carWidth/2, carHeight/2, 1);
    renderTexture1.draw(image12, carWidth/2, carHeight*.58, 1);
    renderTexture1.saveTexture('car');
    renderTexture1.destroy(true);

    this.scene.start('GTGGame');
  }
}
