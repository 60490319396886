import {cloneDeep, concat, isEmpty, isPlainObject} from 'lodash';

import initialState from './initialState';
import * as types from '../constants/actionTypes';
import {calculateTotalPaginatedDataSize} from "src/js/utils/ApiHelper";

export default function walletReducer(state = cloneDeep(initialState.admin), action) {
  switch (action.type) {

    case types.FETCH_BUSINESSES_WALLETS: {
      state.businessesWallets.errMsg = '';
      state.businessesWallets.errMsgF = '';
      state.businessesWallets.isLoading = true;
      return state;
    }
    case types.FETCH_CACHE_BUSINESSES_WALLETS: {
      if (state.cacheListData && state.cacheListData.data && state.cacheListData.type === action.type) {
        state.businessesWallets.paginated = state.cacheListData.data;
        state.businessesWallets.scrollTo = state.cacheListData.scrollPosition;
        state.businessesWallets.isFetchInProgress = false;
        state.businessesWallets.cache = true;
      } else {
        state.businessesWallets.cacheMiss = true;
      }
      return state;
    }
    case types.FETCH_BUSINESSES_WALLETS_SUCCESS: {
      state.businessesWallets.isLoading = false;
      const data = isPlainObject(action.data) && action.data;
      if (!isEmpty(data)) {
        if (data.page === 1) {
          state.businessesWallets.paginated = data;
        } else {
          state.businessesWallets.paginated.data = concat(state.businessesWallets.paginated.data, data.data);
        }
        state.businessesWallets.paginated.page = data.page;
        state.businessesWallets.paginated.perPage = data.perPage;
        state.businessesWallets.paginated.canLoadMore = data.canLoadMore;
        state.businessesWallets.paginated.totalSize = calculateTotalPaginatedDataSize(data);
      }
      return state;
    }
    case types.FETCH_BUSINESSES_WALLETS_FAILURE: {
      state.businessesWallets.isLoading = false;
      if (action.data && action.data.error) {
        state.businessesWallets.errMsg = action.data.error;
      } else {
        state.businessesWallets.errMsgF = 'generic.server_error_msg';
      }
      return state;
    }

    default:
      return state;
  }

}
