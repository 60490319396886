import {toString} from 'lodash';
import * as types from '../constants/actionTypes';
import { wrapCall } from '../utils/serviceUtils';
import { api } from '../utils/services';
import auth from '../utils/auth';
import constants from '../constants';
import uaParser from '../utils/userAgentParser';
import {
  DO_INITIATE_VERIFY_ACCOUNT, DO_SETTING_NOTIFICATION_UPDATE,
  REFRESH_USER_FROM_STORE,
  RESET_INITIATE_VERIFY_ACCOUNT_ERROR_MESSAGE
} from "../constants/actionTypes";

export const resetProfileErrMsg = () => {
  return {
    type: types.RESET_PROFILE_ERROR_MESSAGE
  }
};

export const resetInitiateVerifyAccountErrMsg = () => {
  return {
    type: types.RESET_INITIATE_VERIFY_ACCOUNT_ERROR_MESSAGE
  }
};

export const updateProfileMode = () => {
  return {
    type: types.UPDATE_PROFILE_MODE
  };
};

export const cancelProfileEdit = () => {
  return {
    type: types.CANCEL_PROFILE_UPDATE
  }
};

export const updateProfileData = (field, value) => {
  return {
    type: types.UPDATE_PROFILE_DATA,
    field,
    value
  };
};

export const validateProfileData = (field, value) => {
  return {
    type: types.VALIDATE_PROFILE_DATA,
    field,
    value
  }
}

export const resetProfileData = (fields) => {
  return {
    type: types.RESET_PROFILE_DATA,
    fields
  };
};

export const showChangeSettingsModal = () => {
  return {
    type: types.SHOW_CHANGE_SETTINGS_MODAL
  };
}

export const hideChangeSettingsModal = () => {
  return {
    type: types.HIDE_CHANGE_SETTINGS_MODAL
  };
}

export const doChangeSettings = (settings) => {
  return wrapCall(
    api.post(`/user/me/settings`, settings),
    types.DO_CHANGE_SETTINGS,
    {...settings}
  );
};

export const showChangeMobileModal = () => {
  return {
    type: types.SHOW_CHANGE_MOBILE_MODAL
  };
}

export const hideChangeMobileModal = () => {
  return {
    type: types.HIDE_CHANGE_MOBILE_MODAL
  };
}

export const doGetMobileVerificationOtp = (mobileNumber) => {
  return wrapCall(
    api.post(`/user/me/mobileVerifications`, {mobileNumber}),
    types.DO_FETCH_MOBILE_VERIFICATION,
    {mobileNumber}
  );
};

export const doChangeMobile = (verificationCode, mobileNumber) => {
  return wrapCall(
    api.post(`/user/me/mobileNumbers`, {verificationCode, mobileNumber}),
    types.DO_CHANGE_MOBILE_NUMBER,
    {mobileNumber}
  );
};

export const doProfileUpdate = (data) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('token', auth.getToken());

  return wrapCall(
    api.post(`/user/me/profile`, formData, {'Content-Type': 'multipart/form-data'}),
    types.DO_PROFILE_UPDATE
  );
};

export const doSettingsUpdate = (data) => {
  return wrapCall(
    api.post(`/user/me/settings`, data),
    types.DO_SETTING_NOTIFICATION_UPDATE,
    {requestData: data}
  );
};

export const doAccountUpdate = (data) => {
  let formData = new FormData();
  for (let key in data) {
    formData.append(key, data[key]);
  }
  formData.append('token', auth.getToken());
  return wrapCall(
    api.post(`/user/me`, formData, {'Content-Type': 'multipart/form-data'}),
    types.DO_ACCOUNT_UPDATE,
    {moveToPath: data.moveToPath}
  );
};

export const doInitiateVerifyAccount = () => {
  return wrapCall(
    api.get(`/user/auth/verifyAccount`),
    types.DO_INITIATE_VERIFY_ACCOUNT
  );
};
export const doVerifyAccount = (data) => {
  return wrapCall(
    api.post(`/auth/verifyUser`, data),
    types.DO_VERIFY_ACCOUNT
  );
};
export const refreshUser = () => {
  return {
    type: types.REFRESH_USER_FROM_STORE
  }
};

export const resetChangePasswordErrMsg = () => {
  return {
    type: types.RESET_PROFILE_ERROR_MESSAGE
  }
};
export const doChangePassword = (data) => {
  return wrapCall(
    api.post(`/user/me/passwords`, data),
    types.DO_CHANGE_PASSWORD
  );
};

export const fetchProfile = () => {
  return wrapCall(
    api.get(`/user/me`),
    types.FETCH_PROFILE
  );
}

export const fetchCategories = (id) => {
  return wrapCall(
    api.get(`/categories?page=1&perPage=1000${id ? '&categoryId=' + id : ''}`),
    types.FETCH_CATEGORIES,
    {id}
  );
};

export const doRegisterDevice = (token) => {
  const user = auth.getUser();
  if (!user || !user.id) {
    return {
      type: types.DO_REGISTER_DEVICE_FAILURE
    }
  }
  return wrapCall(
    api.post(`/user/me/devices`, {
      device: {
        platform: constants.app.platform,
        appVersion: constants.app.appVersion,
        deviceModel: uaParser.os.name,
        platformVersion: toString(uaParser.os.version),
        pushNotificationToken: token || null
      }
    }),
    types.DO_REGISTER_DEVICE,
    {requestData: {token: token}}
  );
};
